@font-face {
  font-family: "a Atmospheric";
  src: url("../fonts/aAtmospheric.woff2") format("woff2"), url("../fonts/aAtmospheric.woff") format("woff"),
    url("../fonts/aAtmospheric.ttf") format("truetype"), url("../fonts/aAtmospheric.svg#aAtmospheric") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "a Atmospheric";
  src: url("../fonts/aAtmospheric_1.woff2") format("woff2"), url("../fonts/aAtmospheric_1.woff") format("woff"),
    url("../fonts/aAtmospheric_1.ttf") format("truetype"), url("../fonts/aAtmospheric_1.svg#aAtmospheric") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.eot");
  src: url("../fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff"),
    url("../fonts/Inter-Bold.ttf") format("truetype"), url("../fonts/Inter-Bold.svg#Inter-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Black.eot");
  src: url("../fonts/Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Black.woff2") format("woff2"), url("../fonts/Inter-Black.woff") format("woff"),
    url("../fonts/Inter-Black.ttf") format("truetype"), url("../fonts/Inter-Black.svg#Inter-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-ExtraBold.eot");
  src: url("../fonts/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-ExtraBold.woff2") format("woff2"), url("../fonts/Inter-ExtraBold.woff") format("woff"),
    url("../fonts/Inter-ExtraBold.ttf") format("truetype"),
    url("../fonts/Inter-ExtraBold.svg#Inter-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.eot");
  src: url("../fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff"),
    url("../fonts/Inter-Medium.ttf") format("truetype"), url("../fonts/Inter-Medium.svg#Inter-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.eot");
  src: url("../fonts/Inter-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Light.woff2") format("woff2"), url("../fonts/Inter-Light.woff") format("woff"),
    url("../fonts/Inter-Light.ttf") format("truetype"), url("../fonts/Inter-Light.svg#Inter-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-ExtraLight.eot");
  src: url("../fonts/Inter-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-ExtraLight.woff2") format("woff2"), url("../fonts/Inter-ExtraLight.woff") format("woff"),
    url("../fonts/Inter-ExtraLight.ttf") format("truetype"),
    url("../fonts/Inter-ExtraLight.svg#Inter-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.eot");
  src: url("../fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype"), url("../fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.eot");
  src: url("../fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff"),
    url("../fonts/Inter-SemiBold.ttf") format("truetype"),
    url("../fonts/Inter-SemiBold.svg#Inter-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Thin.eot");
  src: url("../fonts/Inter-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Thin.woff2") format("woff2"), url("../fonts/Inter-Thin.woff") format("woff"),
    url("../fonts/Inter-Thin.ttf") format("truetype"), url("../fonts/Inter-Thin.svg#Inter-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BlackItalic.eot");
  src: url("../fonts/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-BlackItalic.woff2") format("woff2"), url("../fonts/Poppins-BlackItalic.woff") format("woff"),
    url("../fonts/Poppins-BlackItalic.ttf") format("truetype"),
    url("../fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Black.eot");
  src: url("../fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Black.woff2") format("woff2"), url("../fonts/Poppins-Black.woff") format("woff"),
    url("../fonts/Poppins-Black.ttf") format("truetype"), url("../fonts/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBoldItalic.eot");
  src: url("../fonts/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBold.eot");
  src: url("../fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraBold.woff2") format("woff2"), url("../fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BoldItalic.eot");
  src: url("../fonts/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-BoldItalic.woff2") format("woff2"), url("../fonts/Poppins-BoldItalic.woff") format("woff"),
    url("../fonts/Poppins-BoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.eot");
  src: url("../fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraLight.woff2") format("woff2"), url("../fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/Poppins-ExtraLight.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-LightItalic.eot");
  src: url("../fonts/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-LightItalic.woff2") format("woff2"), url("../fonts/Poppins-LightItalic.woff") format("woff"),
    url("../fonts/Poppins-LightItalic.ttf") format("truetype"),
    url("../fonts/Poppins-LightItalic.svg#Poppins-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Italic.eot");
  src: url("../fonts/Poppins-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Italic.woff2") format("woff2"), url("../fonts/Poppins-Italic.woff") format("woff"),
    url("../fonts/Poppins-Italic.ttf") format("truetype"),
    url("../fonts/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype"),
    url("../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLightItalic.eot");
  src: url("../fonts/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLightItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraLightItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-MediumItalic.eot");
  src: url("../fonts/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-MediumItalic.woff2") format("woff2"), url("../fonts/Poppins-MediumItalic.woff") format("woff"),
    url("../fonts/Poppins-MediumItalic.ttf") format("truetype"),
    url("../fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBoldItalic.eot");
  src: url("../fonts/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Thin.eot");
  src: url("../fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Thin.woff2") format("woff2"), url("../fonts/Poppins-Thin.woff") format("woff"),
    url("../fonts/Poppins-Thin.ttf") format("truetype"), url("../fonts/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ThinItalic.eot");
  src: url("../fonts/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ThinItalic.woff2") format("woff2"), url("../fonts/Poppins-ThinItalic.woff") format("woff"),
    url("../fonts/Poppins-ThinItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kallisto";
  src: url("../fonts/Kallisto-Bold.eot");
  src: url("../fonts/Kallisto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kallisto-Bold.woff2") format("woff2"), url("../fonts/Kallisto-Bold.woff") format("woff"),
    url("../fonts/Kallisto-Bold.ttf") format("truetype"), url("../fonts/Kallisto-Bold.svg#Kallisto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kallisto";
  src: url("../fonts/Kallisto-Heavy.eot");
  src: url("../fonts/Kallisto-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kallisto-Heavy.woff2") format("woff2"), url("../fonts/Kallisto-Heavy.woff") format("woff"),
    url("../fonts/Kallisto-Heavy.ttf") format("truetype"),
    url("../fonts/Kallisto-Heavy.svg#Kallisto-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kallisto";
  src: url("../fonts/Kallisto-Light.eot");
  src: url("../fonts/Kallisto-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kallisto-Light.woff2") format("woff2"), url("../fonts/Kallisto-Light.woff") format("woff"),
    url("../fonts/Kallisto-Light.ttf") format("truetype"),
    url("../fonts/Kallisto-Light.svg#Kallisto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kallisto";
  src: url("../fonts/Kallisto-Medium.eot");
  src: url("../fonts/Kallisto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kallisto-Medium.woff2") format("woff2"), url("../fonts/Kallisto-Medium.woff") format("woff"),
    url("../fonts/Kallisto-Medium.ttf") format("truetype"),
    url("../fonts/Kallisto-Medium.svg#Kallisto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kallisto";
  src: url("../fonts/Kallisto-Thin.eot");
  src: url("../fonts/Kallisto-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kallisto-Thin.woff2") format("woff2"), url("../fonts/Kallisto-Thin.woff") format("woff"),
    url("../fonts/Kallisto-Thin.ttf") format("truetype"), url("../fonts/Kallisto-Thin.svg#Kallisto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

$f_primary: "Poppins";
$f_secondary: "Poppins";
$f_poppins: "Poppins";
$f_kallisto: "Poppins";
body {
  font-family: $f_primary !important;
  background-color: #000000 !important;
  &.dashboard_pages {
    background-color: #ffffff !important;
  }
}
.chakra-container {
  max-width: 1280px !important;
  margin: 0 auto;
}
.def_h1 {
  font-family: $f_primary !important;
  font-size: 36px !important;
  line-height: 54px !important;
  text-align: center !important;
  letter-spacing: 0.08em !important;
  color: #ffffff !important;

  font-weight: 700 !important;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  span {
    background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  @media screen and (max-width: 480px) {
    font-size: 28px !important;
    line-height: 32px !important;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  .hdr_innr {
    padding: 15px 110px 8px 47px;
    .logo {
      padding-top: 0px;
    }
  }
  .cntr_cntnt {
    margin-left: auto;
    margin-right: 90px;
    ui {
      display: flex;
      list-style: none;
      li {
        margin: 0 40px;
        a {
          cursor: pointer;
          font-family: $f_poppins;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          text-decoration: none;
          color: #ffffff;
          transition: all 0.2s ease-out;
          &:hover {
            color: #f8b755;
          }
        }
      }
    }
  }
  .bg_design_bx {
    background: url(../img/cntr_header.svg) no-repeat center;
    width: 780px;
    min-height: 76px;
    background-size: 777px;
    display: flex;
    align-items: center;
  }
  .hdr_btn_bx {
    //   padding-top: 16px;
    .hdr_btn {
      font-family: $f_secondary;
      font-size: 15.6461px;
      color: #ffffff;
      text-decoration: none;
      background-color: transparent;
      width: 210px;
      height: 46px;
      background-size: 210px !important;
      transition: all 0.2s ease-out;
      margin-left: 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      line-height: 19px;
      // font-family: 'Inter';
      cursor: pointer;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .emrgncy_btn {
      background: url(../img/emergency_bg.png) no-repeat center;
      margin-right: 93px;
      &:hover {
        color: #ff3232;
      }
    }
    .cntct_us_btn {
      background: url(../img/contact_us_bg.png) no-repeat center;
      &:hover {
        color: #f7ac52;
      }
    }
    .login_btn {
      background: url(../img/login_bg.png) no-repeat center;
      &:hover {
        color: #f7ac52;
      }
    }
  }
}
.header.scrolled {
  background-color: rgb(0 0 0 / 70%);
  box-shadow: 0px 0px 25px #d19bfc;
}
@media screen and (max-width: 1800px) {
  .header {
    .cntr_cntnt {
      margin-right: 50px;
    }
    .hdr_btn_bx .login_btn {
      margin-left: 15px !important;
    }
  }
}
@media screen and (max-width: 1680px) {
  .header {
    .hdr_innr {
      padding: 15px 60px 8px 40px;
    }
    .cntr_cntnt ui li {
      margin: 0 25px;
    }
  }
  .header .bg_design_bx {
    width: 640px;
    min-height: 66px;
    background-size: 640px;
  }
}
@media screen and (max-width: 1550px) {
  .header .cntr_cntnt ui li {
    margin: 0 15px;
  }
  .header .cntr_cntnt {
    margin-right: 90px;
  }
  .header .bg_design_bx {
    width: 560px;
    min-height: 56px;
    background-size: 558px;
  }
  .header .hdr_btn_bx .login_btn {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 1450px) {
  .header .cntr_cntnt {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1350px) {
  .header .cntr_cntnt {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1320px) {
  .header .hdr_innr {
    padding: 15px 36px 8px 24px;
  }
  .header .hdr_btn_bx .emrgncy_btn {
    margin-right: 40px;
  }
}
@media screen and (max-width: 1279px) {
  .header .hdr_btn_bx .hdr_btn {
    width: 180px;
    height: 40px;
    background-size: 175px !important;
    font-size: 14px;
  }
  .header .hdr_btn_bx .emrgncy_btn {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .header .hdr_btn_bx {
    display: none;
  }
  .header .cllps_main .hdr_btn_bx {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header .cntr_cntnt {
    display: none;
  }
  .header .hdr_btn_bx {
    padding-top: 0px;
  }
  .header .hdr_innr .logo {
    padding-top: 0;
  }
  .header {
    .tggl_btn {
      height: 25px;
      width: 34px;
      color: #fff;
      margin-right: 12px;
      background: #705125 !important;
      padding: 14px;
      min-width: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .cllps_main {
    .innr_cllps {
      background: rgba(0, 0, 0, 0.7);
      margin-top: 0px;
    }
  }
  .mbl_links {
    border: none !important;
    padding-left: 5px !important;
    margin-top: 0 !important;
    ui {
      list-style: none;
      li {
        margin: 5px 0;
        a {
          font-family: $f_poppins;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          text-decoration: none;
          color: #ffffff;
          transition: all 0.2s ease-out;
        }
      }
    }
  }
  .header .hdr_btn_bx .emrgncy_btn {
    margin-right: 0;
  }
  .header .hdr_btn_bx .hdr_btn {
    margin: 10px 0 !important;
  }
  .header .hdr_btn_bx .login_btn {
    margin-left: 0px !important;
  }
  .header .hdr_innr {
    padding: 12px 24px 8px 16px;
  }
}
@media screen and (max-width: 767px) {
  .hdr_innr {
    .css-1ef8uzr {
      justify-content: start;
    }
  }
  // .header .hdr_btn_bx button {
  //     width: 120px;
  //     height: 28px;
  //     background-size: 119px !important;
  //     font-size: 12px;
  // }
  // .header .hdr_btn_bx .login_btn {
  //     margin-left: 5px !important;
  // }
}
@media screen and (max-width: 600px) {
  .header .hdr_innr {
    padding: 10px 16px 8px 16px;
  }
  // .header .tggl_btn{
  //     height: 22px;
  //     width: 28px;
  //     padding: 10px;
  //     margin-right: 5px;
  //     min-width: 0;
  // }
  .header .hdr_btn_bx {
    padding-top: 5px;
  }
  .header .hdr_innr .logo {
    width: 240px;
  }
  .mbl_links {
    padding-left: 0;
  }
  .cllps_main .innr_cllps {
    padding: 8px;
  }
}
@media screen and (max-width: 500px) {
  // .header .hdr_btn_bx .cntct_us_btn{
  //     position: fixed;
  //     bottom: 7px;
  //     right: 7px;
  // }
  .header .tggl_btn {
    svg {
      width: 16px;
    }
  }
  .header .hdr_btn_bx .hdr_btn {
    font-size: 14px;
    width: 150px;
    height: 36px;
    background-size: 149px !important;
    margin: 8px 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .header .hdr_innr .logo {
    width: 170px;
  }
}
.rslt_drvn_bx {
  padding-top: 240px;
  background: url(../img/rsltdrvn_bg.jpg) no-repeat left top;
  .hdng_bx {
    margin-top: 100px;
    h1 {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 66px;
      line-height: 73px;
      letter-spacing: -0.03em;
      color: #ffffff;
      margin-bottom: 95px;
      span {
        background: -webkit-linear-gradient(45deg, #ffef61, #ffa451);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .hire_us_btn {
      background-color: transparent;
      background: url(../img/hire_us_bg.png) no-repeat center;
      width: 238px;
      height: 52px;
      background-size: 237px;
      font-family: $f_secondary;
      font-weight: 700;
      font-size: 17.6482px;
      color: #ffffff;
      transition: all 0.2s ease-out;
      &:hover {
        color: #f7ac52;
      }
    }
  }
  .img_cntnt_bx {
    .two_img_bx {
      position: relative;
      .design_img {
        z-index: 1;
      }
      .pluses_img {
        position: absolute;
        right: -156px;
        bottom: -47px;
        z-index: -1;
      }
    }
  }
}
.trstd_dev_main_bx {
  background: url(../img/trstd_dev_main_bg.png) no-repeat right;
  background-size: cover;
  padding-top: 78px;
  padding-bottom: 95px;
  margin-top: -87px;
  .bg_desidn_bx {
    background: url(../img/trstdev_bg.png) no-repeat center;
    width: 608px;
    height: 440px;
    background-size: 607px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    p {
      font-family: $f_secondary;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.03em;
      color: #ffffff;
      max-width: 485px;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .txt_cntnt_bx {
    h1 {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      letter-spacing: -0.03em;
      color: #ffffff;
      margin-top: 170px;
      .grdnt_txt {
        background: -webkit-linear-gradient(45deg, #ffef61, #ffa451);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .brdr_txt {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #fff;
        color: transparent;
        font-size: 86px;
        line-height: 98px;
      }
      .grdnt_brdr_txt {
        background: -webkit-linear-gradient(45deg, #ffef61, #db0a5f);
        -webkit-background-clip: text;
        -webkit-text-stroke: 4px transparent;
        color: #000000;
        font-size: 82px;
        line-height: 98px;
      }
    }
  }
}
.blank_bx {
  min-height: 200px;
}
@media screen and (max-width: 1450px) {
  .rslt_drvn_bx {
    padding-top: 180px;
  }
  .rslt_drvn_bx .hdng_bx h1 {
    font-size: 60px;
    line-height: 68px;
    margin-bottom: 60px;
  }
  .trstd_dev_main_bx {
    padding-bottom: 75px;
    .txt_cntnt_bx h1 {
      font-size: 56px;
      line-height: 74px;
      .brdr_txt {
        font-size: 82px;
        line-height: 92px;
      }
      .grdnt_brdr_txt {
        font-size: 78px;
        line-height: 90px;
      }
    }
  }
  .blank_bx {
    min-height: 100px;
  }
}
@media screen and (max-width: 1280px) {
  .rslt_drvn_bx .hdng_bx h1 {
    font-size: 54px;
    line-height: 60px;
    margin-bottom: 45px;
  }
  .rslt_drvn_bx .hdng_bx .hire_us_btn {
    width: 220px;
    height: 55px;
    background-size: 219px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 {
    font-size: 48px;
    line-height: 64px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .brdr_txt {
    font-size: 72px;
    line-height: 78px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .grdnt_brdr_txt {
    font-size: 69px;
    line-height: 76px;
  }
  .trstd_dev_main_bx .bg_desidn_bx {
    width: 520px;
    height: 380px;
    background-size: 519px;
    p {
      max-width: 455px;
    }
  }
  .trstd_dev_main_bx {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1150px) {
  .rslt_drvn_bx {
    padding-top: 120px;
  }
  .trstd_dev_main_bx {
    padding-bottom: 175px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 {
    font-size: 42px;
    line-height: 60px;
    margin-top: 75px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .brdr_txt {
    font-size: 62px;
    line-height: 72px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .grdnt_brdr_txt {
    font-size: 58px;
    line-height: 64px;
  }
  .trstd_dev_main_bx {
    margin-top: -65px;
  }
}
@media screen and (max-width: 991px) {
  .rslt_drvn_bx {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .rslt_drvn_bx .hdng_bx {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .rslt_drvn_bx .img_cntnt_bx .two_img_bx .design_img {
    margin: 0 auto;
  }
  .trstd_dev_main_bx .bg_desidn_bx {
    margin: 30px auto 0 auto;
  }
  .trstd_dev_main_bx {
    margin: 0;
    padding: 0;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 {
    margin: 40px auto 0 auto;
    text-align: center;
  }
  .rslt_drvn_bx .hdng_bx h1 {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .rslt_drvn_bx .hdng_bx h1 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }
  .rslt_drvn_bx .hdng_bx .hire_us_btn {
    width: 200px;
    height: 48px;
    background-size: 199px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 {
    font-size: 36px;
    line-height: 42px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .brdr_txt {
    font-size: 50px;
    line-height: 56px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .grdnt_brdr_txt {
    font-size: 48px;
    line-height: 54px;
  }
}
@media screen and (max-width: 575px) {
  .trstd_dev_main_bx .bg_desidn_bx {
    width: 440px;
    height: 325px;
    background-size: 439px;
  }
  .trstd_dev_main_bx .bg_desidn_bx p {
    max-width: 369px;
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 480px) {
  .trstd_dev_main_bx .bg_desidn_bx {
    width: 290px;
    height: 213px;
    background-size: 289px;
  }
  .trstd_dev_main_bx .bg_desidn_bx p {
    max-width: 228px;
    font-size: 14px;
    line-height: 14px;
  }
  .rslt_drvn_bx .hdng_bx h1 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .rslt_drvn_bx .hdng_bx {
    margin-top: 20px;
  }
  .rslt_drvn_bx {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .rslt_drvn_bx .hdng_bx .hire_us_btn {
    font-size: 15px;
    width: 150px;
    height: 36px;
    background-size: 149px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .brdr_txt {
    font-size: 40px;
    line-height: 50px;
  }
  .trstd_dev_main_bx .txt_cntnt_bx h1 .grdnt_brdr_txt {
    font-size: 36px;
    line-height: 40px;
  }
}
///// OurWorks /////
.our_wrk_main_bx {
  // background: linear-gradient(90deg, #2E0926 0%, rgba(46, 9, 38, 0) 100%);
  background: #000000 url(../img/our_build_bg.jpg) no-repeat center 0;
  background-size: cover;
  padding: 110px 0 124px 0;
  // h1{
  //     font-family: $f_primary;
  //     font-weight: 400;
  //     font-size: 64.0231px;
  //     line-height: 71px;
  //     letter-spacing: -0.03em;
  //     background: -webkit-linear-gradient(45deg, #FFEF61, #DB0A5F);
  //     -webkit-background-clip: text;
  //     -webkit-text-stroke: 2px transparent;
  //     color: #000000;
  //     display: inline-block;
  //     margin-bottom: 40px;
  // }
  .def_h1 {
    margin-bottom: 67px;
  }
  .show_all_btn {
    display: flex;
    justify-content: center;
    margin-top: 97px;
    a {
      background: url(../img/show_all_bg.svg) no-repeat center;
      width: 190px;
      height: 40px;
      background-size: 189px;
      font-family: $f_poppins;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-out;
      &:focus {
        box-shadow: none;
      }
      &:hover,
      &:focus {
        font-weight: 700 !important;
      }
      span {
        background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
.ourwrk_bxs {
  background: #000000;
  border: 1px solid #9fa4b4;
  .img_tp_bx {
    padding: 6px 8px 12px 8px;
    img {
      margin: 0 auto;
    }
  }
  .grdnt_bttm_bx {
    background: linear-gradient(270.07deg, #5a113d -0.47%, rgba(167, 86, 11, 0.81) 97.06%);
    border-top: 1px solid #9fa4b4;
    display: flex;
    justify-content: center;
  }
  .same_btn {
    font-family: $f_poppins;
    font-weight: 600;
    font-size: 12.6377px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #000000;
    background: #ffffff;
    border-radius: 0;
    width: 130px;
    height: 34px;
    position: relative;
    top: -8px;
    border: 0.702097px solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
    &:focus {
      box-shadow: none;
    }
    &:hover,
    &:focus {
      background: #c4c4c4;
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 1280px) {
  .ourwrk_bxs .same_btn {
    width: 115px;
  }
  .our_wrk_main_bx h1 {
    font-size: 54px;
    line-height: 60px;
  }
  .our_wrk_main_bx {
    padding: 80px 0 100px 0;
  }
}
@media screen and (max-width: 991px) {
  .our_wrk_main_bx {
    padding: 80px 0 100px 0;
  }
  .our_wrk_main_bx h1 {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 30px;
  }
  .our_wrk_main_bx .show_all_btn a {
    width: 160px;
    height: 34px;
    background-size: 159px;
  }
  .ourwrk_bxs .img_tp_bx img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .our_wrk_main_bx h1 {
    font-size: 42px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  .our_wrk_main_bx {
    padding: 60px 0 80px 0;
  }
  .our_wrk_main_bx .show_all_btn {
    margin-top: 46px;
    button {
      width: 145px;
      height: 30px;
      background-size: 144px;
    }
  }
  .our_wrk_main_bx .def_h1 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 575px) {
  .our_wrk_main_bx h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .ourwrk_bxs .same_btn {
    width: 120px;
    min-height: 0;
    height: 32px;
    top: -10px;
  }
  .our_wrk_main_bx .show_all_btn a {
    width: 120px;
    height: 24px;
    background-size: 119px;
  }
  .our_wrk_main_bx .show_all_btn {
    margin-top: 36px;
  }
  .our_wrk_main_bx {
    padding: 48px 0 36px 0;
  }
  .our_wrk_main_bx .def_h1 {
    margin-bottom: 36px;
  }
}
.ftr_tp_img {
  margin-top: -25px;
}
.footer_main {
  position: relative;
  .auto_srolling_box {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 260px;
    p {
      font-family: $f_kallisto !important;
      font-weight: 300;
      font-size: 16px;
      line-height: 36px;
      color: #ffffff;
      opacity: 0.6;
    }
    .dvlgn_txt {
      font-family: $f_kallisto !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      opacity: 0.6;
    }
  }
  .chakra-container {
    max-width: 1530px !important;
  }
  // background: transparent url(../img/footer_bg.jpg) no-repeat right 0;
  background: #130422;
  position: relative;
  .footer_bg_bx {
    background: url(../img/footer_bg.png) no-repeat left;
    background-size: cover;
  }
  .ftr_innr_bx {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 105px;
    .devlpng_main_bx {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 58px;
      .dvlpng_innr_bx {
        h4 {
          font-family: $f_primary;
          font-size: 24.9462px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
          margin-bottom: 12px;
          font-weight: 500;
          font-size: 24.9462px;
          line-height: 37px;
          text-align: center;
          letter-spacing: -0.03em;
        }
        p {
          font-family: $f_secondary;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
          max-width: 300px;
        }
        .logo_and_link_bx {
          display: flex;
          align-items: center;
          a {
            position: relative;
            margin-left: 6px;
            font-family: $f_poppins;
            font-weight: 400;
            font-size: 21.2104px;
            line-height: 25px;
            // text-decoration-line: underline;
            background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            display: flex;
            align-items: center;
            &::before {
              content: "";
              position: absolute;
              top: 100%;
              width: 100%;
              left: 0;
              height: 1px;
              border-radius: 0px;
              background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
            }
            &:hover,
            &:focus {
              text-decoration: underline;
            }
            img {
              margin-left: 6px;
            }
          }
        }
      }
    }
    .ftr_link_bx {
      display: flex;
      align-items: center;
      margin: 50px auto 40px auto;
      a {
        position: relative;
        top: 0;
        margin: 0 15px;
        transition: all 0.2s ease-out;
        &:hover {
          top: -5px;
        }
      }
    }
    .ftr_design_bgbx {
      // position: relative;
      // bottom: -2px;
      // background: url(../img/footer_design.svg) no-repeat center;
      // width: 645px;
      // height: 62px;
      // background-size: 644px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 41px;
      p {
        font-family: $f_secondary;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
        margin-bottom: 2px;
        // padding-top: 12px;
        a {
          border-bottom: 1px solid #ffffff;
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .footer_main .ftr_innr_bx {
    padding-top: 75px;
  }
  .footer_main .ftr_innr_bx .ftr_design_bgbx p {
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .footer_main .ftr_innr_bx .devlpng_main_bx {
    flex-direction: column;
    justify-content: center;
    .dvlpng_innr_bx {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      h4 {
        margin-bottom: 0px;
      }
    }
  }
  .footer_main .ftr_innr_bx {
    padding-top: 116px;
  }
  .footer_main .ftr_innr_bx .devlpng_main_bx {
    margin-top: 60px;
  }
  .footer_main .ftr_innr_bx .ftr_design_bgbx {
    margin-top: 12px;
  }
  .footer_main .ftr_innr_bx .ftr_design_bgbx p {
    padding-bottom: 0px;
    margin-bottom: 40px;
  }
  .footer_main {
    padding-top: 45px;
    .auto_srolling_box {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  // .footer_main .ftr_innr_bx .ftr_design_bgbx{
  //     width: 520px;
  //     height: 50px;
  //     background-size: 519px;
  // }
  .footer_main .ftr_innr_bx {
    .ftr_logo {
      width: 300px;
    }
  }
  .footer_main .ftr_innr_bx .devlpng_main_bx {
    margin-top: 40px;
  }
  .footer_main .ftr_innr_bx .ftr_link_bx {
    margin: 25px auto 25px auto;
  }
  .footer_main {
    padding-top: 36px;
    .auto_srolling_box {
      padding-left: 0;
    }
    .mrq_box {
      height: 36px !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .footer_main .ftr_innr_bx .ftr_design_bgbx {
    // width: 450px;
    // height: 42px;
    // background-size: 449px;
    P {
      font-size: 12px;
    }
  }
}
// @media screen and (min-width: 500px) {
//     .footer_main .ftr_innr_bx .ftr_design_bgbx p{
//         br{ display: none; }
//     }
// }
@media screen and (max-width: 500px) {
  .footer_main .ftr_innr_bx {
    padding-top: 50px;
  }
  .footer_main .ftr_innr_bx .ftr_logo {
    width: 236px;
  }
  .footer_main .ftr_innr_bx .devlpng_main_bx .dvlpng_innr_bx h4 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .footer_main .ftr_innr_bx .devlpng_main_bx .dvlpng_innr_bx .logo_and_link_bx a {
    font-size: 18px;
  }
  .footer_main .ftr_innr_bx .devlpng_main_bx .dvlpng_innr_bx {
    margin-bottom: 48px;
  }
  .footer_main .ftr_innr_bx .ftr_design_bgbx P {
    max-width: 256px;
    line-height: 18px;
  }
  .footer_main {
    padding-top: 32px;
    .auto_srolling_box {
      padding-left: 0;
      p,
      .dvlgn_txt {
        font-size: 14px;
        line-height: 32px;
      }
    }
    .mrq_box {
      height: 32px !important;
    }
  }
}
.prtnr_main_bx {
  padding-top: 115px;
  padding-bottom: 190px;
  // background: url(../img/prtnr_lines_bg.svg) no-repeat center bottom 60px;
  background: #000000 url(../img/our_cmpny_line_bg.svg) no-repeat center bottom;
  overflow: hidden;
  margin-bottom: 3px;
  // h2{
  //     font-family: $f_primary;
  //     font-weight: 400;
  //     font-size: 36px;
  //     line-height: 40px;
  //     text-align: center;
  //     letter-spacing: -0.03em;
  //     color: #FFFFFF;
  //     margin-bottom: 94px;
  //     span{
  //         background: -webkit-linear-gradient(45deg, #FFEF61, #FFA451);
  //         -webkit-background-clip: text;
  //         -webkit-text-fill-color: transparent;
  //     }
  // }
  .def_h1 {
    margin-bottom: 84px;
  }
  .prtnr_innr_bx {
    position: relative;
    min-height: 480px;
    .lft_side_arrw {
      position: absolute;
      top: 0;
      left: 0;
    }
    .rght_side_arrw {
      position: absolute;
      top: 6px;
      left: 815px;
    }
    .you_are_here_txt {
      position: absolute;
      top: -10px;
      left: 1121.5px;
      font-family: $f_secondary;
      line-height: 23px;
      background: linear-gradient(270.07deg, #ffba7b -0.47%, #fff7af 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
      font-size: 30px;
      line-height: 23px;
    }
    .blckldr_img {
      position: absolute;
      top: -16px;
      left: 394px;
      p {
        font-family: $f_secondary;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #ffffff;
      }
      .pdng_lft_bx {
        padding-left: 78px;
      }
      a {
        display: flex;
        align-items: center;
        font-family: $f_poppins;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        background: linear-gradient(273.39deg, #8af8ad 24.3%, #e3f880 98.03%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        span {
          text-decoration-line: underline;
          text-decoration-color: #8af8ad;
          text-underline-offset: 2px;
        }
        img {
          margin-left: 8px;
          width: 10px !important;
          height: 11px !important;
        }
      }
    }
    .blckvd_img {
      position: absolute;
      top: 110px;
      left: 771px;
    }
    .devlegion_img {
      position: absolute;
      bottom: 22px;
      left: 990px;
      .devlegion_html_bx {
        position: relative;
        background: url(../img/our_cmpny_devlegion_bg.svg) no-repeat center;
        width: 562px;
        height: 144px;
        .dvlgn_img {
          position: absolute;
          top: 34px;
          left: 58px;
        }
        p {
          position: absolute;
          bottom: 42px;
          left: 140px;
          font-family: $f_poppins;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
      .bttm_line_img {
        margin-top: 20px;
        padding-left: 148px;
      }
    }
    .dvdr_arrw_img {
      position: absolute;
      top: 17px;
      left: 317px;
    }
  }
  .btgrphx_prnt_bx {
    position: absolute;
    top: 140px;
    left: 386px;
    .bitgraphix_html_bx {
      position: relative;
      background: url(../img/our_cmpny_btgrphx_bg.svg) no-repeat center;
      width: 570px;
      height: 145px;
      .btgrphx_img {
        position: absolute;
        top: 42px;
        left: 64px;
      }
      .def_link {
        position: absolute;
        bottom: 32px;
        left: 162px;
      }
    }
  }
  .blckguru_prnt_bx {
    position: absolute;
    top: 360px;
    left: 386px;
    .blckguru_html_bx {
      position: relative;
      background: url(../img/our_cmpny_blckguru_bg.svg) no-repeat center;
      width: 562px;
      height: 141px;
      .blckguru_img {
        position: absolute;
        top: 24px;
        left: 48px;
      }
      p {
        position: absolute;
        right: 30px;
        top: 48px;
        font-family: $f_poppins;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        color: #ffffff;
        max-width: 227px;
      }
      .def_link {
        position: absolute;
        bottom: 16px;
        left: 160px;
      }
    }
  }
  .def_link {
    display: flex;
    align-items: center;
    font-family: $f_poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    background: linear-gradient(273.39deg, #8af8ad 24.3%, #e3f880 98.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span {
      text-decoration-line: underline;
      text-decoration-color: #8af8ad;
      text-underline-offset: 2px;
    }
    img {
      margin-left: 8px;
      width: 10px !important;
      height: 11px !important;
    }
  }
}
@media screen and (max-width: 1750px) {
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .bttm_line_img {
    padding-left: 120px;
  }
}
@media screen and (max-width: 1700px) {
  .prtnr_main_bx .prtnr_innr_bx .rght_side_arrw {
    width: 985px;
    left: 715px;
  }
  .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
    width: 330px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: -15px;
    left: 345px;
    img {
      width: 350px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .blckvd_img {
    left: 690px;
    img {
      width: 300px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    bottom: 50px;
    left: 1010px;
    a {
      img {
        width: 380px;
      }
    }
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    position: absolute;
    top: 303px;
    left: 320px;
    .blckguru_html_bx {
      width: 450px;
      height: 116px;
      background-size: 445px;
      .def_link {
        bottom: 10px;
        left: 116px;
      }
      .blckguru_img {
        top: 23px;
        left: 34px;
        width: 160px;
      }
      p {
        right: 14px;
        top: 30px;
      }
    }
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    position: absolute;
    top: 130px;
    left: 320px;
    .bitgraphix_html_bx {
      width: 450px;
      height: 116px;
      background-size: 445px;
      .def_link {
        bottom: 22px;
        left: 145px;
      }
      .btgrphx_img {
        top: 36px;
        left: 61px;
        width: 320px;
      }
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    bottom: 88px;
    left: 804px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx {
    width: 450px;
    height: 116px;
    background-size: 445px;
    .dvlgn_img {
      top: 26px;
      left: 52px;
      width: 300px;
    }
    p {
      bottom: 30px;
      left: 116px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .bttm_line_img {
    padding-left: 128px;
    width: 475px;
    margin-top: 10px;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    left: 1089.5px;
  }
  .prtnr_main_bx {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}
@media screen and (max-width: 1500px) {
  .prtnr_main_bx {
    padding-top: 70px;
    .prtnr_innr_bx {
      min-height: 400px;
    }
    h2 {
      margin-bottom: 70px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
    width: 260px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: -14px;
    left: 272px;
    img {
      width: 300px;
    }
    // p{
    //     font-size: 16px;
    //     line-height: 23px;
    //     max-width: 225px;
    //     margin-top: 25px;
    // }
  }
  .prtnr_main_bx .prtnr_innr_bx .rght_side_arrw {
    width: 900px;
    left: 585px;
    top: 3px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckvd_img {
    left: 580px;
    top: 95px;
    img {
      width: 250px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    bottom: 25px;
    left: 750px;
    // a{
    //     img{
    //         width: 340px;
    //     }
    // }
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img .pdng_lft_bx {
    padding-left: 63px;
    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    top: 120px;
    left: 260px;
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    top: 285px;
    left: 260px;
  }
  .prtnr_main_bx .def_h1 {
    margin-bottom: 60px;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    left: 945.5px;
    font-size: 24.73px;
    line-height: 24px;
    top: -13px;
  }
}
@media screen and (max-width: 1310px) {
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .bttm_line_img {
    width: 442px;
  }
}
@media screen and (max-width: 1279px) {
  .prtnr_main_bx .prtnr_innr_bx .rght_side_arrw {
    display: none;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckvd_img {
    left: 272px;
    top: 175px;
    img {
      width: 300px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    bottom: 0px;
    left: 325px;
    top: 394px;
    a {
      img {
        width: 340px;
      }
    }
    .bttm_line_img {
      display: none;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
    width: auto;
    left: -110px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: -9px;
    left: 325px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    top: 110px;
    left: 325px;
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    top: 250px;
    left: 325px;
  }
  .prtnr_main_bx {
    padding-bottom: 220px;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    left: 127px;
    font-size: 24.73px;
    line-height: 24px;
    top: 442px;
  }
}
@media screen and (max-width: 991px) {
  .prtnr_main_bx {
    padding-top: 40px;
    h2 {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 50px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: -14px;
    img {
      width: 291px;
    }
  }
  // .prtnr_main_bx .prtnr_innr_bx .blckldr_img p{
  //     line-height: 20px;
  //     max-width: 321px;
  //     margin-top: 12px;
  //     padding-left: 8px;
  // }
  .prtnr_main_bx .prtnr_innr_bx .blckvd_img {
    top: 150px;
  }
  .prtnr_main_bx .prtnr_innr_bx {
    min-height: 375px;
  }
}
@media screen and (max-width: 800px) {
  .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
    left: -230px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: -6px;
    left: 175px;
    img {
      width: 260px;
    }
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img .pdng_lft_bx {
    padding-left: 55px;
    p {
      font-size: 11px;
      line-height: 10px;
    }
  }
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx {
    width: 358px;
    height: 94px;
    background-size: 356px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx .btgrphx_img {
    top: 28px;
    left: 52px;
    width: 250px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx .def_link {
    bottom: 14px;
    left: 116px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    left: 175px;
    top: 112px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx {
    width: 358px;
    height: 94px;
    background-size: 356px;
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    left: 175px;
    top: 255px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx .blckguru_img {
    top: 20px;
    left: 34px;
    width: 136px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx .blckguru_img {
    top: 18px;
    left: 24px;
    width: 116px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx p {
    right: 10px;
    top: 21px;
    font-size: 10px;
    line-height: 14px;
    max-width: 190px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx .def_link {
    bottom: 9px;
    left: 85px;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    left: 39px;
    font-size: 16px;
    line-height: 20px;
    top: 442px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    left: 175px;
    top: 406px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx {
    width: 358px;
    height: 94px;
    background-size: 356px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx .dvlgn_img {
    top: 22px;
    left: 40px;
    width: 240px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx p {
    bottom: 27px;
    left: 90px;
    font-size: 8px;
    line-height: 12px;
  }
}
// @media screen and (max-width: 767px) {
//     .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw{
//         left: -140px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckldr_img{
//         left: 130px;
//         p{
//             line-height: 17px;
//             max-width: 262px;
//             margin-top: 10px;
//             padding-left: 8px;
//             font-size: 14px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckvd_img{
//         left: 140px;
//         top: 125px;
//         img{
//             width: 250px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .devlegion_img{
//         left: 140px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx{
//         min-height: 325px;
//     }
// }
// @media screen and (max-width: 575px) {
//     .prtnr_main_bx {
//         padding-top: 30px;
//         h2{
//             font-size: 30px;
//             line-height: 35px;
//             margin-bottom: 45px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw{
//         left: -145px;
//         width: 230px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckldr_img{
//         left: 90px;
//         top: -13px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckvd_img{
//         left: 100px;
//         top: 120px;
//         img{
//             width: 230px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .devlegion_img{
//         left: 100px;
//         a{
//             img{
//                 width: 270px;
//             }
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx {
//         min-height: 290px;
//     }
// }
@media screen and (max-width: 575px) {
  .prtnr_main_bx .def_h1 {
    margin-bottom: 36px;
  }
  .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
    display: none;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
    top: 0;
    left: calc(50% - 130px);
    max-width: 260px;
  }
  .prtnr_main_bx .prtnr_innr_bx .blckldr_img .pdng_lft_bx p {
    line-height: 13px;
    margin-bottom: 4px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    left: calc(50% - 179px);
    top: 120px;
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    left: calc(50% - 179px);
    top: 245px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    left: calc(50% - 179px);
    top: 390px;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    left: calc(50% - 59px);
    top: 370px;
  }
}
// @media screen and (max-width: 480px) {
//     .prtnr_main_bx{
//         padding-top: 25px;
//         h2{
//             font-size: 24px;
//             line-height: 28px;
//             margin-bottom: 40px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .lft_side_arrw {
//         left: -140px;
//         width: 180px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckldr_img {
//         left: 45px;
//         top: -11px;
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckldr_img{
//         p{
//             max-width: 205px;
//             margin-top: 4px;
//         }
//         img{
//             width: 220px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .blckvd_img{
//         left: 55px;
//         top: 115px;
//         img{
//             width: 200px;
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx .devlegion_img{
//         left: 55px;
//         a{
//             img{
//                 width: 200px;
//             }
//         }
//     }
//     .prtnr_main_bx .prtnr_innr_bx {
//         min-height: 255px;
//     }
// }
@media screen and (max-width: 479px) {
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx {
    width: 298px;
    height: 80px;
    background-size: 296px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx {
    left: calc(50% - 149px);
    top: 120px;
  }
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx .btgrphx_img {
    top: 25px;
    left: 38px;
    width: 216px;
  }
  .prtnr_main_bx .def_link img {
    margin-left: 3px;
    width: 9px !important;
    height: 8px !important;
  }
  .prtnr_main_bx .btgrphx_prnt_bx .bitgraphix_html_bx .def_link {
    bottom: 12px;
    left: 95px;
    font-size: 12px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx {
    width: 298px;
    height: 80px;
    background-size: 296px;
  }
  .prtnr_main_bx .blckguru_prnt_bx {
    left: calc(50% - 149px);
    top: 230px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx .blckguru_img {
    top: 15px;
    left: 32px;
    width: 96px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx p {
    right: 10px;
    top: 18px;
    font-size: 8px;
    line-height: 10px;
    max-width: 151px;
  }
  .prtnr_main_bx .blckguru_prnt_bx .blckguru_html_bx .def_link {
    bottom: 7px;
    left: 65px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx {
    width: 298px;
    height: 80px;
    background-size: 296px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img {
    left: calc(50% - 149px);
    top: 368px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx .dvlgn_img {
    top: 20px;
    left: 32px;
    width: 216px;
  }
  .prtnr_main_bx .prtnr_innr_bx .devlegion_img .devlegion_html_bx p {
    bottom: 21px;
    left: 78px;
    font-size: 7px;
    line-height: 10px;
    white-space: nowrap;
  }
  .prtnr_main_bx .prtnr_innr_bx .you_are_here_txt {
    top: 345px;
  }
  .prtnr_main_bx {
    padding-bottom: 160px;
  }
}
.our_team_main_bx {
  padding-top: 55px;
  overflow: hidden;
  background: url(../img/our_team_bg.jpg) no-repeat center 0;
  min-height: 1076px;
  .hdng_bx {
    position: relative;
    h1 {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 64.0231px;
      line-height: 71px;
      letter-spacing: -0.03em;
      color: #ffffff;
      display: inline-block;
      span {
        background: -webkit-linear-gradient(45deg, #ffef61, #ffa451);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-family: $f_secondary;
        font-weight: 500;
        font-size: 37px;
        line-height: 26px;
        letter-spacing: -0.03em;
        color: #ffffff;
        text-align: right;
      }
    }
    .ourteam_dsgn_img {
      position: absolute;
      left: 417px;
      top: 13px;
    }
  }
  .ourteam_innr_cntnt {
    position: relative;
    margin-top: 120px;
    .liner_img {
      margin: 0 auto;
    }
    .img_01 {
      position: absolute;
      top: 7px;
      left: 93px;
    }
    .img_02 {
      position: absolute;
      top: 7px;
      left: 370px;
    }
    .img_03 {
      position: absolute;
      top: 7px;
      left: 709px;
    }
    .img_04 {
      position: absolute;
      top: 282px;
      left: 370px;
    }
    .img_05 {
      position: absolute;
      top: 282px;
      left: 540px;
    }
    .img_06 {
      position: absolute;
      top: 282px;
      left: 709px;
    }
    .img_07 {
      position: absolute;
      top: 282px;
      left: 877px;
    }
    .img_08 {
      position: absolute;
      top: 280px;
      left: 1062px;
    }
    .img_09 {
      position: absolute;
      top: 140px;
      left: 1062px;
    }
    .img_10 {
      position: absolute;
      top: 418px;
      left: 236px;
    }
    .team_lgn_img {
      position: absolute;
      top: 497px;
      left: 898px;
    }
    .txt_bx {
      position: absolute;
      top: 540px;
      left: 545px;
      .ourteam_kyc_txt {
        font-family: $f_secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: justify;
        letter-spacing: -0.03em;
        background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        max-width: 326px;
      }
    }
  }
}
@media screen and (max-width: 1550px) {
  .our_team_main_bx .hdng_bx {
    h1 {
      font-size: 54.0231px;
      line-height: 62px;
      p {
        font-size: 28px;
        line-height: 16px;
      }
    }
  }
  .our_team_main_bx .hdng_bx .ourteam_dsgn_img {
    left: 350px;
    top: 11px;
  }
}
@media screen and (max-width: 1279px) {
  .our_team_main_bx {
    min-height: 1320px;
    background-size: cover;
    padding-top: 40px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .liner_img {
    display: none;
  }
  .our_team_main_bx .ourteam_innr_cntnt {
    margin-top: 50px;
  }
  .our_team_main_bx .ourteam_innr_cntnt {
    .img_01 {
      top: 0;
      left: 100px;
    }
    .img_02 {
      top: 0;
      left: 660px;
    }
    .img_03 {
      top: 170px;
      left: 100px;
    }
    .img_04 {
      top: 170px;
      left: 660px;
    }
    .img_05 {
      top: 350px;
      left: 100px;
    }
    .img_06 {
      top: 350px;
      left: 660px;
    }
    .img_07 {
      top: 530px;
      left: 100px;
    }
    .img_08 {
      top: 530px;
      left: 660px;
    }
    .img_09 {
      top: 710px;
      left: 100px;
    }
    .img_10 {
      top: 710px;
      left: 660px;
    }
  }
  .our_team_main_bx .ourteam_innr_cntnt .txt_bx {
    top: 915px;
    left: 100px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .team_lgn_img {
    top: 880px;
    left: 460px;
  }
  .our_team_main_bx .hdng_bx h1 {
    font-size: 48.0231px;
    line-height: 54px;
    p {
      font-size: 24px;
      line-height: 12px;
    }
  }
  .our_team_main_bx .hdng_bx .ourteam_dsgn_img {
    left: 313px;
    top: 8px;
  }
}
@media screen and (max-width: 900px) {
  .our_team_main_bx .ourteam_innr_cntnt {
    .img_01 {
      top: 0;
      left: 100px;
    }
    .img_02 {
      top: 0;
      left: 490px;
    }
    .img_03 {
      top: 170px;
      left: 100px;
    }
    .img_04 {
      top: 170px;
      left: 490px;
    }
    .img_05 {
      top: 350px;
      left: 100px;
    }
    .img_06 {
      top: 350px;
      left: 490px;
    }
    .img_07 {
      top: 530px;
      left: 100px;
    }
    .img_08 {
      top: 530px;
      left: 490px;
    }
    .img_09 {
      top: 710px;
      left: 100px;
    }
    .img_10 {
      top: 710px;
      left: 490px;
    }
  }
}
@media screen and (max-width: 767px) {
  .our_team_main_bx {
    padding-top: 30px;
  }
  .our_team_main_bx .ourteam_innr_cntnt {
    .img_01 {
      top: 0;
      left: 50px;
    }
    .img_02 {
      top: 0;
      left: 390px;
    }
    .img_03 {
      top: 170px;
      left: 50px;
    }
    .img_04 {
      top: 170px;
      left: 390px;
    }
    .img_05 {
      top: 350px;
      left: 50px;
    }
    .img_06 {
      top: 350px;
      left: 390px;
    }
    .img_07 {
      top: 530px;
      left: 50px;
    }
    .img_08 {
      top: 530px;
      left: 390px;
    }
    .img_09 {
      top: 710px;
      left: 50px;
    }
    .img_10 {
      top: 710px;
      left: 390px;
    }
  }
  .our_team_main_bx .ourteam_innr_cntnt .team_lgn_img {
    top: 895px;
    left: 330px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .txt_bx {
    top: 915px;
    left: 50px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .txt_bx .ourteam_kyc_txt {
    max-width: 271px;
  }
  .our_team_main_bx .hdng_bx h1 {
    font-size: 36.0231px;
    line-height: 40px;
    p {
      font-size: 17px;
      line-height: 8px;
    }
  }
  .our_team_main_bx .hdng_bx .ourteam_dsgn_img {
    left: 232px;
    top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .our_team_main_bx {
    min-height: 1580px;
  }
  .our_team_main_bx .hdng_bx {
    text-align: center;
  }
  .our_team_main_bx .hdng_bx .ourteam_dsgn_img {
    display: none;
  }
  .our_team_main_bx .ourteam_innr_cntnt {
    .img_01 {
      top: 0;
      left: 15px;
    }
    .img_02 {
      top: 0;
      left: auto;
      right: 25px;
    }
    .img_03 {
      top: 160px;
      left: 15px;
    }
    .img_04 {
      top: 160px;
      left: auto;
      right: 25px;
    }
    .img_05 {
      top: 340px;
      left: 15px;
    }
    .img_06 {
      top: 340px;
      left: auto;
      right: 25px;
    }
    .img_07 {
      top: 520px;
      left: 15px;
    }
    .img_08 {
      top: 520px;
      left: auto;
      right: 25px;
    }
    .img_09 {
      top: 700px;
      left: 15px;
    }
    .img_10 {
      top: 700px;
      left: auto;
      right: 25px;
    }
  }
  .our_team_main_bx .ourteam_innr_cntnt .txt_bx {
    top: 870px;
    left: 15px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .txt_bx .ourteam_kyc_txt {
    max-width: 250px;
  }
  .our_team_main_bx .ourteam_innr_cntnt .team_lgn_img {
    top: 1060px;
    left: calc(50% - 99px);
  }
  .our_team_main_bx .hdng_bx h1 {
    font-size: 32.0231px;
    line-height: 40px;
    p {
      font-size: 15px;
    }
  }
  .our_team_main_bx .ourteam_innr_cntnt {
    margin-top: 35px;
  }
  .our_team_main_bx {
    min-height: 1460px;
  }
}
/////////////
.sec_wat_prnt {
  background: url(../img/full_stack_srvc_bg.jpg) no-repeat center;
  background-size: cover;
  padding: 124px 0 112px 0;
  // .def_h1 {
  //   margin-bottom: 76px;
  // }
  .new_peragp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    max-width: 730px;
    color: #FFFFFF;
    margin: 13px auto 70px auto;
  }
  .wat_hed {
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      margin-top: 179px;
      margin-bottom: 88px;
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
      span {
        color: #ffc057;
      }
    }
  }
  .smrt_grd_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h6 {
      margin-top: 25px;
      margin-bottom: 19px;
      font-family: $f_primary !important;
      font-style: normal;
      font-size: 26px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
      font-weight: 700;
      line-height: 39px;
    }
    p {
      max-width: 300px;
      font-family: $f_secondary !important;
      // margin-bottom: 170px;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
    }
    // .smar_img_03 {
    //     min-height: 175px;
    // }
  }
}
@media screen and (max-width: 1280px) {
  .sec_wat_prnt {
    padding: 80px 0 80px 0;
  }
}
@media screen and (max-width: 1080px) {
  .smrt_grd_box {
    h6 {
      font-size: 20px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .sec_wat_prnt .wat_hed h4 {
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 20px;
  }
  .sec_wat_prnt .smrt_grd_box p {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .sec_wat_prnt .smrt_grd_box h6 {
    margin-top: 18px;
    margin-bottom: 10px;
    font-size: 22px !important;
  }
  .sec_wat_prnt {
    padding: 60px 0 40px 0;
  }

}
@media screen and (max-width: 767px) {
  .sec_wat_prnt .new_peragp {
    font-size: 16px;
    line-height: 22px;
    margin: 13px auto 40px auto;
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .sec_wat_prnt .new_peragp {
    margin: 13px auto 20px auto;
    line-height: 20px;
  }
  .sec_wat_prnt {
    padding: 40px 0 24px 0;
  }
  // .sec_wat_prnt .def_h1 {
  //   margin-bottom: 36px !important;
  // }
}
/////////////////
.Report_bg_box {
  padding: 210px 16px 138px 16px;
  background: url(../img/report_cntct_bg.jpg) no-repeat center 0;
  background-size: cover;
  .reprt_gred_box {
    // background: url(../img/report_bg_img01.jpg) no-repeat 0 0 !important;
    // background-size: cover !important;
    // min-height: 939.14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 36px;
    .report_img_box {
      background: url(../img/raport_box_img001.png) no-repeat 0 0 !important;
      background-size: contain !important;
      width: 595.01px;
      height: 474.37px;
      margin-left: auto;
      position: relative;
      .report_hdng {
        font-family: $f_primary;
        letter-spacing: -0.03em;
        color: #ad00ff;
        text-align: right;
        position: absolute;
        top: -60px;
        left: calc(50% - 93px);
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
      }
      .clint_head {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 22px;
        @media screen and (min-width: 601px) {
          margin-bottom: 45px;  
        }
        h4 {
          font-family: $f_poppins !important;
          font-style: normal;
          font-size: 28px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 1px;
          font-weight: 600;
          line-height: 42px;
          letter-spacing: -0.03em;
          text-transform: uppercase;
        }
        span {
          background: linear-gradient(270.07deg, #bf00c2 -0.47%, #7900d8 97.06%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        p {
          font-family: $f_secondary !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          text-transform: uppercase;
          // max-width: 305px;
          margin-bottom: 47px;
          font-family: $f_poppins;
        }
      }

      .prgrf_single {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 31px;

        p {
          font-family: $f_poppins !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
          span {
            text-decoration-line: underline;
          }
        }
      }
      .btn_bg_clint_box {
        display: flex;
        align-items: center;
        margin-top: 80px;
        .btn_box_deco {
          background: url(../img/btn_img_clint_02.png) no-repeat 0 0;
          background-size: cover;
          width: 211px;
          height: 46.56px;
          font-family: $f_poppins !important;
          font-style: normal;
          font-weight: 700;
          font-size: 15.6461px;
          line-height: 19px;
          color: #ffffff;
          margin-bottom: 40px;
          margin: 0 auto;
          transition: all 0.2s ease-out;
          &:hover,
          &:focus {
            box-shadow: none;
            color: #bb36ff;
          }
        }
      }
    }
  }
  .reprt_gred_box_v2 {
    // background: url(../img/report_bg_img02.jpg) no-repeat 0 0 !important;
    // background-size: cover !important;
    // min-height: 939.14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 36px;
    .report_img_box_v2 {
      padding-bottom: 20px;
      background: url(../img/right_clant_img.png) no-repeat 0 0;
      background-size: 100% 100% !important;
      width: 590.01px;
      // height: 455.37px;
      margin-right: auto;
      position: relative;
      .contact_hdng {
        font-family: $f_primary;
        color: #fff500;
        position: absolute;
        top: -60px;
        left: calc(50% - 103.5px);
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: -0.03em;
        text-transform: uppercase;
      }
      .clint_head {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 22px;
        h4 {
          font-family: $f_poppins !important;
          font-style: normal;
          font-size: 28px;
          line-height: 31px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
          font-weight: 600;
          line-height: 42px;
          text-transform: uppercase;
          span {
            background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        p {
          font-family: $f_poppins !important;
          font-style: normal;
          line-height: 26px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
          max-width: 305px;
          margin-bottom: 21px;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
        }
      }
      .btn_bg_clint_box {
        display: flex;
        align-items: center;
        .btn_box_deco {
          background: url(../img/btn_img_clint_02.png) no-repeat 0 0;
          background-size: cover;
          width: 211px;
          height: 46.56px;
          font-family: $f_poppins !important;
          font-style: normal;
          font-weight: 700;
          font-size: 15.6461px;
          line-height: 19px;
          color: #ffffff;
          margin: -10px auto 0px auto;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .btn_bg_clint_box_v2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 13px;
        .btn_box_deco_v2 {
          background: url(../img/right_box_btn_img02.png) no-repeat 0 0;
          background-size: cover;
          margin-top: 8px;
          width: 211px;
          height: 46.56px;
          font-family: $f_poppins !important;
          font-style: normal;
          font-weight: 700;
          font-size: 15.6461px;
          line-height: 19px;
          color: #ffffff;
          transition: all 0.2s ease-out;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            color: #f7ac52;
          }
          &:disabled {
            cursor: no-drop;
          }
        }
      }
    }
  }
}
.input_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  input,
  textarea {
    max-width: 426px;
    min-height: 57px;
    margin: 0 auto;
    width: 100%;
    outline: none;
    border: #969696 1px solid;
    background: transparent;
    padding-left: 15px;
    padding-right: 15px;
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: #ffffff;
    &::placeholder {
      color: #ffffff;
    }
  }
}
.input_box_error {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    max-width: 426px;
    margin: 0 auto;
    margin-top: -15px;
    width: 100%;
    outline: none;
    background: transparent;
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: red;
  }
}

.input_box_v2 {
  margin-top: 10px !important;
  height: 92px;
  padding: 5px 15px 5px 15px;
  resize: none !important;
}

.align_center {
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .Report_bg_box {
    padding: 160px 16px 138px 16px;
    .flex_colum {
      flex-direction: column;
      display: flex;
    }
    .reprt_gred_box {
      // padding-right: 10px !important;
      .report_img_box {
        margin: 0 auto;
      }
    }
  }
  .reprt_gred_box_v2 {
    padding: 0;
    .report_img_box_v2 {
      margin: 0 !important;
      margin-top: 132px !important;
    }
  }
  .Report_bg_box .reprt_gred_box_v2 {
    padding-left: 0 !important;
  }
  .Report_bg_box .reprt_gred_box {
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  // .Report_bg_box .reprt_gred_box{
  //     min-height: 916.14px
  // }
  .report_img_box .clint_head {
    margin-top: 30px !important;
  }
  .input_box input {
    // max-width: 400px;
    // min-height: 55px;
  }
  .report_img_box .prgrf_single {
    margin-top: 24px !important;
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 600px) {
  .Report_bg_box {
    padding: 100px 16px 120px 16px;
    .reprt_gred_box {
      //    padding-right: 10px;
      //    min-height: 516.14px;
      .clint_head {
        margin-top: 20px !important;
      }
      .report_img_box {
        width: 400px;
        height: 330px;
      }
    }
    h4 {
      font-size: 20px !important;
      margin-bottom: 0 !important;
    }
    .clint_head p {
      margin-bottom: 18px !important;
      font-size: 12px !important;
    }
    .input_box {
      margin-bottom: 12px;
    }
    .input_box input,
    .input_box textarea {
      max-width: 275px;
      min-height: 40px;
      font-size: 12px;
    }
  }
  .Report_bg_box .reprt_gred_box .report_img_box .btn_bg_clint_box {
    margin-top: 55px;
  }
  .Report_bg_box .reprt_gred_box .report_img_box .report_hdng {
    font-size: 32px;
    line-height: 34px;
    top: -50px;
    left: calc(50% - 83px);
  }
  .Report_bg_box .reprt_gred_box .report_img_box .btn_bg_clint_box .btn_box_deco {
    width: 180px;
    height: 39.56px;
    font-size: 14px;
  }
  .Report_bg_box {
    .reprt_gred_box_v2 {
      // min-height: 539.14px;
      padding-left: 0;
      padding-right: 0;
      .report_img_box_v2 {
        width: 400px;
        height: 330px;
        margin-top: 112px !important;
        .clint_head {
          margin-top: 16px;
          p {
            margin-bottom: 3px !important;
          }
        }
      }
      .input_box_v2 {
        margin-top: 0 !important;
        height: 55px;
        margin-bottom: 15px;
      }
    }
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .btn_bg_clint_box_v2 .btn_box_deco_v2 {
    width: 180px;
    height: 39.56px;
    font-size: 14px;
    margin-top: 0;
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .contact_hdng {
    font-size: 32px;
    line-height: 34px;
    top: -50px;
    left: calc(50% - 92px);
  }
}
@media screen and (max-width: 480px) {
  .Report_bg_box {
    padding: 80px 16px 140px 16px;
  }
  .Report_bg_box .reprt_gred_box .report_img_box {
    width: 284px;
    height: 233px;
  }
  .Report_bg_box .clint_head p {
    margin-bottom: 8px !important;
    font-size: 11px !important;
  }
  .Report_bg_box .reprt_gred_box .report_img_box .report_hdng {
    font-size: 24px;
    line-height: 24px;
    top: -32px;
    left: calc(50% - 62px);
  }
  .Report_bg_box .input_box input,
  .Report_bg_box .input_box textarea {
    max-width: 255px;
    min-height: 38px;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .Report_bg_box .reprt_gred_box .report_img_box .btn_bg_clint_box .btn_box_deco {
    width: 136px;
    height: 29px;
    font-size: 14px;
  }
  .Report_bg_box .reprt_gred_box .report_img_box .btn_bg_clint_box {
    margin-top: 80px;
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .contact_hdng {
    font-size: 24px;
    line-height: 24px;
    top: -32px;
    left: calc(50% - 69px);
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 {
    width: 288px;
    height: 233px;
    margin-top: 150px !important;
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .clint_head {
    margin-top: 6px;
  }
  .Report_bg_box .input_box {
    margin-bottom: 8px;
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .btn_bg_clint_box_v2 .btn_box_deco_v2 {
    width: 136px;
    height: 29px;
    font-size: 14px;
  }
  .Report_bg_box .reprt_gred_box_v2 .report_img_box_v2 .btn_bg_clint_box_v2 {
    margin-top: 24px;
  }
}
///// OurWorkDetails /////
.ourwrk_detail_main {
  background: url(../img/portpolio_bg_aj.jpg) no-repeat center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 195px 0;
  .cntnr_box {
    max-width: 1280px;
    margin: 0 auto;
    padding: 12px;
  }
}
@media screen and (max-width: 1400px) {
  .ourwrk_detail_main {
    padding: 120px 0;
  }
}
@media screen and (max-width: 991px) {
  .ourwrk_detail_main {
    padding: 100px 0;
  }
}
@media screen and (max-width: 767px) {
  .ourwrk_detail_main {
    padding: 80px 0;
  }
}
@media screen and (max-width: 575px) {
  .ourwrk_detail_main {
    padding: 60px 0 80px 0;
  }
}
///// new/ui /////
///// BecomeUnlimited /////
.bcm_unlmtd_main_bx {
  background: url(../img/bcm_unlmtd_bg.png) no-repeat left;
  background-size: cover;
  margin-bottom: 90px;
  padding: 0 16px;
  .hdng_and_btn_bx {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 320px;
    h4 {
      font-family: $f_poppins;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 49.1986px;
      line-height: 74px;
    }
    h2 {
      font-family: $f_poppins;
      font-weight: 700;
      font-size: 56px;
      line-height: 85px;
      text-align: center;
      background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0px 0 112px 0;
      text-transform: uppercase !important;
    }
    .vw_our_wrk_btn {
      background-color: transparent;
      background: url(../img/hire_us_bg.png) no-repeat center;
      width: 238px;
      height: 52px;
      background-size: 237px;
      font-family: $f_poppins;
      font-weight: 600;
      font-size: 17.6482px;
      color: #ffffff;
      transition: all 0.2s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover,
      &:focus {
        color: #f7ac52;
        box-shadow: none;
      }
    }
  }
  .plus_img_bx {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 90%;
    padding: 125px 0 35px 0;
  }
  .warning_grid_box {
    max-width: 1000px;
    margin: 0 auto;
    .warning_bx {
      background: url(../img/warning_bg.png) no-repeat center;
      width: 540px;
      height: 380px;
      background-size: 530px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-family: $f_secondary;
        color: #ffffff;
        max-width: 342px;
        font-weight: 700;
        font-weight: 600;
        font-size: 17px;
        line-height: 31px;
        span {
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.03em;
        }
      }
      .bttm_txt {
        margin-top: 24px;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.02em;
      }
    }
    .circle_bx {
      width: 369px;
      height: 381px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../img/red_circles_img.png) no-repeat center;
      p {
        max-width: 255px;
        font-family: $f_poppins;
        text-align: center;
        color: #ffffff;

        font-weight: 600;
        font-size: 16px;
        line-height: 133.52%;
        span {
          // font-size: 26.0451px;
          line-height: 24px;
          color: #ff2f2f;
        }
      }
    }
  }
}
.flex_cntr {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
@media screen and (max-width: 1600px) {
  .bcm_unlmtd_main_bx {
    margin-bottom: 70px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx {
    padding-top: 260px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    margin: 24px 0 80px 0;
  }
  .bcm_unlmtd_main_bx .plus_img_bx {
    padding: 80px 0 40px 0;
  }
}
@media screen and (max-width: 1450px) {

  .bcm_unlmtd_main_bx .hdng_and_btn_bx {
    padding-top: 220px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    margin: 0px 0 70px 0;
    font-size: 54.5049px;
    line-height: 62px;
  }
  .bcm_unlmtd_main_bx .plus_img_bx {
    padding: 60px 0 40px 0;
  }
}
@media screen and (max-width: 1280px) {
  .bcm_unlmtd_main_bx .hdng_and_btn_bx {
    padding-top: 180px;
  }
  // .bcm_unlmtd_main_bx .hdng_and_btn_bx h4 {
  //   font-size: 20px;
  //   line-height: 26px;
  // }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    margin: 0px 0 60px 0;
    font-size: 44px;
    line-height: 52px;
  }
  .bcm_unlmtd_main_bx .plus_img_bx {
    padding: 50px 0 36px 0;
    img {
      width: 160px;
    }
  }
}
@media screen and (max-width: 1100px) {
  .bcm_unlmtd_main_bx .warning_grid_box .css-1be0d1a {
    grid-column-gap: 90px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h4 {
    font-size: 39.1986px;
    line-height: 66px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    margin: 0px 0 60px 0;
    font-size: 39px;
  }
}
@media screen and (max-width: 991px) {
  .bcm_unlmtd_main_bx .warning_grid_box .css-1be0d1a {
    grid-column-gap: 0px !important;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx {
    margin: 0 auto 40px auto;
  }
}
@media screen and (max-width: 767px) {
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h4 {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    margin: 0px 0 60px 0;
    font-size: 35px !important;
    line-height: 52px !important;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx {
    padding-top: 110px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx .vw_our_wrk_btn {
    width: 220px;
    height: 55px;
    background-size: 219px;
    font-size: 16px;
  }
  .bcm_unlmtd_main_bx .plus_img_bx img {
    width: 140px;
  }
  .bcm_unlmtd_main_bx {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .bcm_unlmtd_main_bx .hdng_and_btn_bx {
    padding-top: 100px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    font-size: 40px;
    line-height: 48px;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx {
    width: 416px;
    height: 310px;
    background-size: 434px;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx p {
    font-size: 18px;
    line-height: 22px;
    max-width: 310px;
    span {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx .bttm_txt {
    padding-right: 0px;
    margin-top: 18px;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .css-1ay212z {
    grid-column-gap: 0 !important;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx .vw_our_wrk_btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx {
    width: 300px;
    height: 236px;
    background-size: 316px;
    margin: 0 auto 24px auto;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx p {
    font-size: 14px;
    line-height: 16px;
    max-width: 241px;
    span {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx .bttm_txt {
    margin-top: 15px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h4 {
    font-size: 18px;
    line-height: 24px;
    max-width: 264px;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx h2 {
    font-size: 34px;
    line-height: 38px;
    max-width: 264px;
    margin: 12px 0 48px 0;
  }
  .bcm_unlmtd_main_bx .hdng_and_btn_bx .vw_our_wrk_btn {
    width: 200px;
    height: 48px;
    background-size: 199px;
  }
  .bcm_unlmtd_main_bx .plus_img_bx {
    padding: 30px 0 30px 0;
    img {
      width: 100px;
    }
  }
  .bcm_unlmtd_main_bx .warning_grid_box .circle_bx {
    width: 312px;
    height: 304px;
    background-size: 284px;
  }
  .bcm_unlmtd_main_bx .warning_grid_box .circle_bx p {
    max-width: 215px;
    font-size: 16px;
    line-height: 24px;
    span {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
///// ThreeBoxSec /////
.three_boxes_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #2d0925 url(../img/second_sec_bg.png) no-repeat center;
  background-size: cover;
  padding: 127px 16px 144px 16px;
}
.trust_priceless_box {
  h3 {
    font-family: $f_primary;
    font-weight: 700;
    font-size: 45px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.13em;
    span {
      display: block;
      font-size: 45px;
      line-height: 46px;
      letter-spacing: 0.13em;
      background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}
.txt_cntnt{
  margin-top: 40px;
}
.three_boxes_main {
  .two_img_and_txt {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: -65px;
    p {
      font-family: $f_secondary;
      font-size: 17.3453px;
      text-align: center;
      color: #ffffff;
      max-width: 440px;
      margin: 0px auto 17px auto;
      font-weight: 600;
      line-height: 26px;
      text-transform: capitalize;
      a {
        font-weight: 600;
        font-size: 21.9367px;
        line-height: 27px;
        background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        transition: all 0.2s ease-out;
        &:hover {
          background: linear-gradient(270.07deg, #f08622 -0.47%, #ffe922 97.06%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      span {
        font-weight: 600;
        font-size: 21.9367px;
        line-height: 27px;
      }
    }
    .bttm_txt {
      max-width: 475px !important;
    }
  }
  .three_grid_boxes {
    margin-top: 76px;
    .three_same_bx {
      background: linear-gradient(270.07deg, rgba(168, 3, 70, 0.13) -0.47%, rgba(255, 24, 118, 0.13) 97.06%);
      border-radius: 19.693px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 17px 18px 17px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border: 1px solid transparent;
        background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        border-radius: 19.693px;
        pointer-events: none;
      }
      p {
        font-family: $f_secondary;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
        max-width: 237px;
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
  .three_grid_boxesA2 {
    margin-top: 0;
    .hedPent{
      margin: 30px 0 20px 0;
      .new_heding {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .three_boxes_main {
    padding: 80px 16px 100px 16px;
  }
}
@media screen and (max-width: 991px) {
  .three_boxes_main .three_grid_boxes .three_same_bx {
    margin: 16px auto;
    max-width: 267px;
  }
  // .three_boxes_main .three_grid_boxes {
  //   margin-top: 50px;
  // }
  .three_boxes_main .two_img_and_txt {
    // margin-top: 36px;
    img {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .three_boxes_main {
    padding: 60px 16px 60px 16px;
  }
  .trust_priceless_box h3 {
    font-size: 34px;
    line-height: 40px;
    span {
      font-size: 36px;
      line-height: 42px;
    }
  }
  .three_boxes_main .two_img_and_txt {
    // margin-top: 30px;
  }
  .three_boxes_main .two_img_and_txt p {
    font-size: 16px;
    line-height: 20px;
    max-width: 386px;
    a {
      font-size: 20px;
      line-height: 24px;
    }
    span {
      font-size: 20px;
      line-height: 24px;
    }
  }
  // .three_boxes_main .three_grid_boxes {
  //   margin-top: 32px;
  // }
  .three_boxes_main .three_grid_boxes {
    .css-1ay212z {
      grid-column-gap: 0 !important;
    }
  }
  .three_boxes_main .three_grid_boxesA2 .hedPent {
    margin: 10px 0 10px 0;
    .new_heding {
      font-size: 19px;
      line-height: 22px;
    }
  }
}
@media screen and (max-width: 480px) {
  .bcm_unlmtd_main_bx .warning_grid_box .warning_bx .bttm_txt {
    font-size: 14px;
    line-height: 17px;
  }
  .three_boxes_main {
    padding: 40px 16px 40px 16px;
  }
  .trust_priceless_box h3 {
    font-size: 28px;
    line-height: 36px;
    span {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .three_boxes_main .two_img_and_txt {
    // margin-top: 24px;
  }
  // .three_boxes_main .three_grid_boxes {
  //   margin-top: 24px;
  // }
  .three_boxes_main .three_grid_boxesA2 .hedPent {
    margin: 10px 0 0px 0;
    .new_heding {
      font-size: 17px;
      line-height: 22px;
    }
  }
}
///// DevTeam /////
.dev_team_main {
  background: #000000 url(../img/dev_team_bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px 0 186px 0;
  .innr_cntnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-family: $f_secondary;
      font-weight: 500;
      font-size: 22px;
      line-height: 42px;
      text-align: center;
      color: #ffffff;
      max-width: 729px;
    }
    h6 {
      font-family: $f_secondary;
      font-weight: 600;
      font-size: 28px;
      line-height: 39px;
      text-align: center;
      color: #ffffff;
      max-width: 741px;
      margin: 54px 0 36px 0;
    }
  }
}
@media screen and (max-width: 1279px) {
  .dev_team_main {
    padding: 100px 0 120px 0;
  }
}
@media screen and (max-width: 991px) {
  .dev_team_main {
    padding: 80px 0 100px 0;
    .innr_cntnt p {
      font-size: 18px;
      line-height: 32px;
      max-width: 594px;
    }
    .innr_cntnt h6 {
      font-size: 24px;
      line-height: 32px;
      max-width: 640px;
      margin: 36px 0 32px 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .dev_team_main {
    padding: 60px 0 80px 0;
  }
  .dev_team_main .innr_cntnt p {
    font-size: 16px;
    line-height: 26px;
    max-width: 524px;
  }
  .dev_team_main .innr_cntnt h6 {
    font-size: 22px;
    line-height: 28px;
    max-width: 588px;
    margin: 32px 0 32px 0;
  }
}
@media screen and (max-width: 600px) {
  .dev_team_main .innr_cntnt p {
    font-size: 14px;
    line-height: 24px;
    max-width: 460px;
  }
  .dev_team_main .innr_cntnt h6 {
    font-size: 20px;
    line-height: 26px;
    max-width: 460px;
    margin: 28px 0 30px 0;
  }
  .dev_team_main {
    padding: 40px 0 60px 0;
  }
}
///// OurResults /////
.our_result_main {
  background: url(../img/our_result_bg.jpg) no-repeat center;
  background-size: cover;
  padding: 124px 16px 144px 16px;
  overflow: hidden;
  .our_rslt_innr {
    position: relative;
    min-height: 480px;
    .our_rslt_bx {
      position: absolute;
      top: 0%;
      left: 19.11%;
      display: flex;
      .def_h1 {
        text-align: left !important;
        white-space: nowrap;
        // padding-left: 45px;
      }
      .ourteam_dsgn_img {
        padding-left: 24px;
        padding-bottom: 10px;
      }
    }
  }
  .our_rslt_txt_cntnt {
    position: absolute;
    top: 140px;
    left: 32.7%;
    .margn_pernt {
      margin-top: 50px;
    }
    p {
      font-family: $f_secondary;
      font-weight: 500;
      text-align: justify;
      letter-spacing: -0.03em;
      color: #ffffff;
      max-width: 922px;
      // margin-top: 113px;

      font-size: 20px;
      line-height: 40px;
      text-align: justify;
      letter-spacing: -0.03em;
      
      a {
        background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        transition: all 0.2s ease-out;
        &:hover,
        &:focus {
          text-decoration: underline #ffa451;
        }
      }
    }
  }
  .are_lgndry_bx {
    position: absolute;
    bottom: 0;
    right: 27.68%;
    display: flex;
    .def_h1 {
      text-align: left !important;
      white-space: nowrap;
      margin-left: -5px;
    }
    .ourteam_dsgn_img {
      margin-bottom: -27px;
    }
  }
}
@media screen and (max-width: 1680px) {
  .our_result_main .our_rslt_txt_cntnt {
    left: 28.7%;
  }
  .our_result_main .are_lgndry_bx {
    left: -150px;
  }
}
@media screen and (max-width: 1550px) {
  .our_result_main .our_rslt_txt_cntnt {
    left: 25.7%;
  }
  .our_result_main .are_lgndry_bx {
    left: -212px;
  }
}
@media screen and (max-width: 1400px) {
  .our_result_main {
    padding: 100px 0 110px 0;
  }
  .our_result_main .our_rslt_txt_cntnt {
    left: 24.7%;
  }
  .our_result_main .are_lgndry_bx {
    left: -240px;
  }
  .our_result_main .our_rslt_txt_cntnt .margn_pernt {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1280px) {
  .our_result_main {
    padding: 80px 0 90px 0;
  }
  .our_result_main .our_rslt_innr {
    min-height: 380px;
  }
  .our_result_main .our_rslt_innr .our_rslt_bx {
    left: 8.11%;
  }
  .our_result_main .our_rslt_txt_cntnt {
    left: 11.7%;
    top: 100px;
    p {
      font-size: 20px;
      line-height: 32px;
      max-width: 832px;
    }
  }
  .our_result_main .are_lgndry_bx {
    left: -380px;
  }
}
@media screen and (max-width: 1099px) {
  .our_result_main {
    padding: 60px 0 80px 0;
  }
  .our_result_main .our_rslt_innr {
    min-height: 350px;
  }
  .our_result_main .our_rslt_innr .our_rslt_bx {
    left: 6%;
  }
  .our_result_main .our_rslt_txt_cntnt {
    left: 10%;
    top: 90px;
  }
  .our_result_main .are_lgndry_bx {
    right: 47.68%;
  }
}
@media screen and (max-width: 991px) {
  .our_result_main .our_rslt_innr .our_rslt_bx .ourteam_dsgn_img {
    padding-left: 10px;
    padding-bottom: 7px;
  }
  .our_result_main .def_h1 {
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .our_result_main .are_lgndry_bx .def_h1 {
    margin-bottom: -4px;
  }
  .our_result_main .our_rslt_txt_cntnt p {
    font-size: 18px;
    line-height: 28px;
    max-width: 680px;
  }
  .our_result_main .our_rslt_txt_cntnt .margn_pernt {
    margin-top: 20px;
  }
}
@media screen and (max-width: 800px) {
  .our_result_main .our_rslt_innr .our_rslt_bx .ourteam_dsgn_img {
    padding-left: 8px;
    padding-bottom: 8px;
  }
  .our_result_main {
    padding: 40px 0 60px 0;
  }
  .our_result_main .our_rslt_innr {
    min-height: 320px;
  }
  .our_result_main .def_h1 {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  .our_result_main .our_rslt_txt_cntnt {
    top: 68px;
  }
  .our_result_main .our_rslt_txt_cntnt p {
    font-size: 16px;
    line-height: 25px;
    max-width: 458px;
  }
  .our_result_main .are_lgndry_bx {
    right: 54.68%;
    left: -200px;
  }
  .our_result_main .are_lgndry_bx .ourteam_dsgn_img {
    margin-bottom: -20px;
  }
}
@media screen and (max-width: 600px) {
  .our_result_main .our_rslt_innr .our_rslt_bx {
    left: 4%;
  }
  .our_result_main .our_rslt_txt_cntnt {
    top: 60px;
    left: 8%;
  }
  .our_result_main .our_rslt_txt_cntnt p {
    max-width: 408px;
  }
  .our_result_main .are_lgndry_bx {
    right: 65%;
  }
}
@media screen and (max-width: 479px) {
  .our_result_main {
    padding: 32px 0 32px 0;
    .our_rslt_innr {
      min-height: 456px;
      .our_rslt_bx {
        left: calc(50% - 105.5px);
      }
    }
  }
  .our_result_main .def_h1 {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .our_result_main .our_rslt_innr .our_rslt_bx .ourteam_dsgn_img {
    display: none;
  }
  .our_result_main .our_rslt_txt_cntnt {
    top: 60px;
    left: calc(50% - 133.5px);
  }
  .our_result_main .our_rslt_txt_cntnt p {
    max-width: 267px;
  }
  .our_result_main .are_lgndry_bx {
    left: calc(50% - 127.17px);
  }
  .our_result_main .are_lgndry_bx .ourteam_dsgn_img {
    display: none;
  }
  .our_result_main .are_lgndry_bx .def_h1 {
    margin-bottom: 0;
    margin-left: 0;
  }
  .our_result_main .our_rslt_txt_cntnt .margn_pernt {
    margin-top: 15px;
  }
}
///// LogIn PopUp /////
.lgn_innr_html {
  background: url(../img/popup_login_bg.png) no-repeat center;
  width: 577px;
  height: 461px;
  margin: 0 auto;
  .txt_cntnt {
    padding-top: 45px;
    h4 {
      font-family: $f_primary;
      font-weight: 600;
      font-size: 28px;
      line-height: 31px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
      text-transform: uppercase;
      span {
        background: linear-gradient(270.07deg, #bf00c2 -0.47%, #7900d8 97.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    p {
      font-family: $f_secondary;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
    }
  }
  .inpt_bx {
    margin-top: 48px;
  }
  .option_box {
    font-family: $f_secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    button {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 0 !important;
      margin-right: 5px !important;
      text-decoration: underline;
      transition: all 0.2s ease-out;
      &:focus,
      &:hover {
        box-shadow: none !important;
        color: #bb36ff;
      }
    }
  }
  .login_btn_bx {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .pop_login_btn {
    background: transparent url(../img/login_purple_btn_bg.png) no-repeat center;
    font-family: $f_secondary;
    font-weight: 500;
    font-size: 15.6461px;
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    width: 210px;
    height: 46px;
    transition: all 0.2s ease-out;
    &:focus,
    &:hover {
      color: #bb36ff;
      box-shadow: none;
      border: none;
      background: transparent url(../img/login_purple_btn_bg.png) no-repeat center !important;
    }
  }
}
.chakra-modal__content-container {
  display: flex !important;
  justify-content: center !important;
  // align-items: flex-start !important;
  background-color: rgba(0, 0, 0, 0.6);
  .chakra-modal__content {
    background: transparent !important;
    margin: 0 !important;

    .chakra-modal__body {
      padding: 0 !important;
      width: 100%;
      z-index: 1;
    }
  }
  .chakra-modal__content.signup_mdlcntn {
    min-width: 578px;
  }
}
.chakra-modal__content-container .chakra-modal__content.login_mdlcntnt {
  min-width: 577px;
}
.signup_popup {
}
.signup_innr_html {
  background: url(../img/popup_signup_bg.png) no-repeat center;
  width: 578px;
  height: 624px;
  .back_and_close_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 490px;
    margin: 0px auto 0 auto;
    padding-top: 4px;
    button {
      font-family: $f_secondary;
      font-weight: 500;
      font-size: 15.8333px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
      background: transparent !important;
      border: none;
      padding: 0 !important;
      display: flex;
      align-items: center;
      transition: all 0.2s ease-out;
      &:hover,
      &:focus {
        box-shadow: none !important;
        color: #f7ac52 !important;
      }
      &:first-child {
        img {
          margin-right: 6px;
        }
      }
      &:last-child {
        img {
          margin-left: 6px;
        }
      }
    }
  }
  .txt_cntnt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    margin-bottom: 31px;
    h4 {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 28px;
      line-height: 31px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
    }
  }
  .slct_box {
    .chakra-select__wrapper {
      max-width: 426px;
      height: 57px;
      .chakra-select {
        border: #969696 1px solid;
        border-radius: 0;
        height: 57px;
        font-family: $f_secondary;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.03em;
        color: #ffffff;
        box-shadow: none !important;
        option {
          color: #000000;
          font-family: $f_secondary;
          font-weight: 300;
          font-size: 14px;
          padding: 8px 0;
        }
      }
      .chakra-select__icon-wrapper {
        svg {
          color: #ffffff !important;
        }
      }
    }
  }
  .signup_btn_bx {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    .pop_signup_btn {
      background: transparent url(../img/popup_signup_btn_bg.png) no-repeat center;
      font-family: $f_secondary;
      font-weight: 500;
      font-size: 15.6461px;
      color: #ffffff;
      text-decoration: none;
      background-color: transparent;
      width: 210px;
      height: 46px;
      transition: all 0.2s ease-out;
      &:focus,
      &:hover {
        color: #f7ac52;
        box-shadow: none;
        border: none;
        background: transparent url(../img/popup_signup_btn_bg.png) no-repeat center !important;
      }
    }
  }
  .option_box {
    font-family: $f_secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
      font-weight: 400;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 0 !important;
      margin-right: 5px !important;
      text-decoration: underline;
      transition: all 0.2s ease-out;
      &:focus,
      &:hover {
        box-shadow: none !important;
        color: #f7ac52;
      }
    }
  }
}
@media screen and (max-width: 630px) {
  .chakra-modal__content-container .chakra-modal__content.signup_mdlcntn {
    min-width: 460px;
  }
  .signup_innr_html {
    width: 460px;
    height: 500px;
    background-size: 460px;
  }
  .signup_innr_html .back_and_close_box {
    max-width: 396px;
  }
  .signup_innr_html .input_box input,
  .signup_innr_html .input_box textarea {
    max-width: 327px;
    min-height: 40px;
  }
  .signup_innr_html .slct_box .chakra-select__wrapper {
    max-width: 327px;
    min-height: 40px;
    .chakra-select {
      height: 40px;
    }
    .chakra-select__icon-wrapper {
      top: 35%;
    }
  }
  .signup_innr_html .txt_cntnt {
    margin-top: 10px;
    margin-bottom: 24px;
    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .signup_innr_html .signup_btn_bx {
    margin-top: 20px;
    .pop_signup_btn {
      font-size: 14px;
      width: 160px;
      height: 40px;
      background-size: 160px;
    }
  }
  .signup_innr_html .option_box {
    margin-top: 8px;
    font-size: 14px;
    button {
      font-size: 14px;
    }
  }
  .chakra-modal__content-container .chakra-modal__content.login_mdlcntnt {
    min-width: 466px;
  }
  .lgn_innr_html {
    width: 464px;
    height: 380px;
    background-size: 464px;
  }
  .input_box input,
  .input_box textarea {
    max-width: 380px;
    min-height: 48px;
  }
  .lgn_innr_html .inpt_bx {
    margin-top: 36px;
  }
  .lgn_innr_html .txt_cntnt {
    padding-top: 36px;
  }
  .lgn_innr_html .txt_cntnt h4 {
    font-size: 24px;
    line-height: 24px;
  }
  .lgn_innr_html .option_box {
    margin-top: 20px;
  }
  .lgn_innr_html .pop_login_btn {
    width: 182px;
    height: 42px;
    background-size: 180px;
  }
  .lgn_innr_html .login_btn_bx {
    margin-top: 12px;
  }
  .forg_btn_pop{
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 500px) {
  .opps_red_p{
    font-size: 13px !important;
  }
  .lgn_innr_html .login_btn_bx {
    margin-top: 7px;
  }
  .chakra-modal__content-container .chakra-modal__content.signup_mdlcntn {
    min-width: 360px;
  }
  .signup_innr_html {
    width: 360px;
    height: 392px;
    background-size: 360px;
    margin: 0 auto;
  }
  .signup_innr_html .back_and_close_box {
    max-width: 314px;
    padding-top: 12px;
    button {
      font-size: 14px;
      line-height: 20px;
      height: 20px;
    }
  }
  .signup_innr_html .txt_cntnt {
    margin-top: 8px;
    margin-bottom: 16px;
    h4 {
      font-size: 20px;
      line-height: 20px;
    }
  }
  .signup_innr_html .input_box input,
  .signup_innr_html .input_box textarea {
    max-width: 275px;
    min-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 20px;
  }
  .signup_innr_html .input_box {
    margin-bottom: 12px;
  }
  .signup_innr_html .slct_box .chakra-select__wrapper {
    max-width: 275px;
    min-height: 34px;
  }
  .signup_innr_html .slct_box .chakra-select__wrapper {
    max-width: 275px;
    height: 34px;
    .chakra-select {
      font-size: 12px;
      height: 34px;
    }
    .chakra-select__icon-wrapper {
      top: 50%;
    }
  }
  .signup_innr_html .signup_btn_bx {
    margin-top: 18px;
  }
  .signup_innr_html .signup_btn_bx .pop_signup_btn {
    font-size: 12px;
    width: 140px;
    height: 32px;
    background-size: 140px;
  }
  .signup_innr_html .option_box {
    margin-top: 10px;
    font-size: 13px;
    line-height: 20px;
    button {
      font-size: 13px;
      height: 20px;
    }
  }
  .lgn_innr_html {
    width: 362px;
    height: 294px;
    background-size: 360px;
  }
  .chakra-modal__content-container .chakra-modal__content.login_mdlcntnt {
    min-width: 362px;
  }
  .lgn_innr_html .txt_cntnt h4 {
    font-size: 22px;
    line-height: 22px;
  }
  .lgn_innr_html .txt_cntnt {
    padding-top: 24px;
  }
  .lgn_innr_html .inpt_bx {
    margin-top: 30px;
  }
  .lgn_innr_html .input_box input,
  .signup_innr_html .input_box textarea {
    max-width: 275px;
    min-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 20px;
  }
  .lgn_innr_html .input_box {
    margin-bottom: 12px;
  }
  .lgn_innr_html .option_box {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    button {
      font-size: 14px;
      height: 22px;
    }
  }
  .lgn_innr_html .pop_login_btn {
    font-size: 12px;
    width: 140px;
    height: 32px;
    background-size: 140px;
  }
  
}
@media screen and (max-width: 380px) {
  .signup_innr_html {
    width: 302px;
    height: 328px;
    background-size: 302px;
  }
  .signup_innr_html .back_and_close_box {
    max-width: 264px;
    padding-top: 8px;
    button {
      font-size: 12px;
      line-height: 16px;
      height: 18px;
    }
  }
  .signup_innr_html .txt_cntnt {
    margin-top: 6px;
    margin-bottom: 10px;
    h4 {
      font-size: 18px;
      line-height: 18px;
    }
  }
  .signup_innr_html .input_box input,
  .signup_innr_html .input_box textarea {
    max-width: 232px;
    min-height: 30px;
  }
  .signup_innr_html .slct_box .chakra-select__wrapper {
    max-width: 230px;
    height: 30px;
  }
  .signup_innr_html .slct_box .chakra-select__wrapper .chakra-select {
    height: 30px;
  }
  .signup_innr_html .input_box {
    margin-bottom: 10px;
  }
  .signup_innr_html .signup_btn_bx {
    .pop_signup_btn {
      width: 130px;
      height: 30px;
      background-size: 130px;
    }
  }
  .signup_innr_html .signup_btn_bx {
    margin-top: 8px;
  }
  .signup_innr_html .option_box {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    button {
      font-size: 12px;
      height: 16px;
      margin-right: 2px !important;
    }
  }
  .lgn_innr_html {
    width: 306px;
    height: 248px;
    background-size: 306px;
  }
  .chakra-modal__content-container .chakra-modal__content.login_mdlcntnt {
    min-width: 306px;
  }
  .lgn_innr_html .txt_cntnt {
    // margin-top: 6px;
    // margin-bottom: 10px;
    h4 {
      font-size: 18px;
      line-height: 18px;
    }
  }
  .lgn_innr_html .txt_cntnt p {
    font-size: 12px;
    line-height: 24px;
  }
  .lgn_innr_html .input_box input,
  .lgn_innr_html .input_box textarea {
    max-width: 232px;
    min-height: 30px;
  }
  .lgn_innr_html .inpt_bx {
    margin-top: 20px;
  }
  .lgn_innr_html .option_box {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    button {
      font-size: 12px;
      height: 16px;
      margin-right: 2px !important;
    }
  }
  .lgn_innr_html .pop_login_btn {
    width: 130px;
    height: 30px;
    background-size: 130px;
  }
  .lgn_innr_html .txt_cntnt {
    padding-top: 20px;
  }
}
.sidbar_main {
  .sidebar_inn {
    border: none !important;
    background: transparent;
    z-index: 9999;
  }
}
.sidebr_inncnnt {
  position: relative;
  z-index: 9;
  padding-top: 13px;
  padding-bottom: 20px;
  padding-left: 30px;
}
.sidebar_border_ic {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.sidebar_logo {
  max-width: 163px;
}
.sidebarlink_prnt {
  padding-left: 30px;
  margin-top: 100px;
  a {
    display: flex;
    align-items: center;
    font-family: $f_primary !important;
    font-style: normal;
    color: #303030;
    transition: all 0.2s ease-out;
    margin-bottom: 29px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      position: relative;
      margin-right: 9px;
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 2px;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
      }
    }
    .user_list_ic_actv {
      display: none;
    }
    &:hover, &.active {
      background: linear-gradient(270.07deg, #2E1209 -0.47%, #F79400 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      .user_list_ic_actv {
        display: block;
      }
      .user_list_ic_non_act {
        display: none;
      }
    }
  }
}
.light_to_darkbtn_prnt {
  position: absolute;
  left: 0;
  bottom: 73px;
  padding-left: 30px;
  button {
    padding: 0;
    font-family: $f_primary !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    background: transparent !important;
    min-height: 0;
    transition: all 0.2s ease-out;
    color: #303030;
    text-transform: uppercase;
    &:focus {
      box-shadow: none;
    }
    span {
      width: 45px;
      height: 20px;
      position: relative;
      background: #dadada;
      border-radius: 40px;
      margin: 0 6px;
      &::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        // background: linear-gradient(270.07deg, #2E0926 -0.47%, #A80346 97.06%);
        background: linear-gradient(317.17deg, #ff8a00 10.34%, #fec600 81.33%);
        border-radius: 50%;
        left: 3px;
        top: 2px;
      }
    }
  }
}
.copyright_bx {
  font-family: $f_primary;
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  color: #303030;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-left: 10px;
  left: 0;
}
.sidbar_main .mbl_menu_togle_btn {
  border: 1px solid #a80346;
  color: #ff1876;
  height: 30px;
  width: 34px;
}
@media screen and (max-width: 991px) {
  .mdl_cntnt_cntnt {
    max-width: 240px;
    background: transparent !important;
    border: none !important;
  }
  .mdl_cntnt_cntnt_prnt {
    max-width: 240px !important;
  }
  .sidebr_inncnnt {
    padding-top: 30px;
    .sidmenu_close {
      position: absolute;
      top: 6px;
      right: 15px;
      z-index: 1;
    }
  }
  .sidebarlink_prnt {
    margin-top: 64px;
  }
  .mbl_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: transparent;
    padding: 16px 26px 12px 16px;
    border: none;
    min-height: 60px;
    &.scrolled {
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 25px #fff;
    }
  }
  .light_to_darkbtn_prnt {
    position: relative;
    left: 0;
    bottom: 0;
    margin-left: auto;
  }
  .d_none_fr0m_991 {
    display: none;
  }
  .sidebar_logo_mbl_prnt {
    margin-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .sidebr_inncnnt {
    padding-left: 20px;
  }
  .sidebarlink_prnt {
    padding-left: 20px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  .sidebarlink_prnt a {
    font-size: 13px;
    span {
      &::before {
        height: 28px;
        width: 28px;
      }
      img {
        max-width: 32px;
      }
    }
  }
  .light_to_darkbtn_prnt {
    padding-left: 0px;
  }
  .sidebar_logo_mbl_prnt {
    margin-left: 10px;
  }
  .mbl_header {
    padding: 10px;
    min-height: 0;
  }
}
@media screen and (max-width: 430px) {
  .sidebar_logo_mbl_prnt {
    .sidebar_logo {
      max-width: 110px;
    }
  }
  .sidbar_main .mbl_menu_togle_btn {
    min-width: 34px;
  }
  .light_to_darkbtn_prnt button span {
    width: 38px;
    height: 16px;
    margin: 0 4px;
    &::before {
      height: 13px;
      width: 13px;
    }
  }
  .light_to_darkbtn_prnt button {
    font-size: 10px;
  }
}
//Userlist
.user_list_btm_sdw_circle_img {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.userlist_page {
  padding-top: 54px;
  padding-bottom: 45px;
  min-height: 100vh;
  .user_list_top_sdw_circle_img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
}
.usr_list_cntnr {
  padding: 0 25px 0 30px;
}
h2.def_head_dbrd {
  display: inline-block;
  font-family: $f_primary !important;
  margin-bottom: 10px;
  // background: linear-gradient(270.07deg, #2E0926 -0.47%, #A80346 97.06%);
  background: linear-gradient(270.07deg, #2e1209 28.53%, #f79400 97.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    text-transform: lowercase;
  }
}
.user_cntnt_box {
  padding: 24px 35px 35px 18px;
  position: relative;
  z-index: 2;
  min-height: 700px;
  .user_list_top_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    z-index: -1;
    width: 100%;
  }
  .user_list_btm_img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 162px;
    z-index: -1;
    width: 100%;
  }
  .user_cntr_line_bg {
    position: absolute;
    bottom: 162px;
    top: 30px;
    left: 0;
    right: 0;
    z-index: -1;
    background: url(../img/user_list_cntr_brdr_bg.png) repeat-y 0 0;
    background-size: contain;
  }
  .user_cntr_line_bg_v2 {
    background: url(../img/user_list_cntr_brdr_bg_v2.png) repeat-y 0 0;
    background-size: contain;
  }
}
.create_user_prnt_raw {
  display: flex;
  justify-content: space-between;
  h5 {
    font-family: $f_primary !important;
    font-size: 22px;
    line-height: 20px;
    color: #303030;
    padding-top: 6px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
button.creat_user_btn {
  padding: 0;
  min-height: 0;
  background: transparent;
  // font-family: 'Montserrat';
  font-family: $f_secondary !important;
  font-weight: 600;
  font-size: 16px;
  background: linear-gradient(317.17deg, #c96c00 10.34%, #ffa800 81.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  height: auto;
  margin-top: -6px;
  cursor: pointer !important;
  img {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background: linear-gradient(317.17deg, #c96c00 10.34%, #ffa800 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.8;
  }
}
.userlist_table_prnt {
  margin-top: 18px;
  table {
    thead {
      tr {
        th {
          border-top: 1px solid rgba(57, 57, 57, 0.5);
          border-bottom: 1px solid rgba(57, 57, 57, 0.5);
          font-family: $f_poppins !important;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 10px;
          color: #303030;
          padding: 16px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: $f_poppins !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 34px;
          color: #303030;
          margin-top: 7px;
          padding: 4px 0;
          border: none;
          &:first-child {
            & > div {
              border-radius: 10px 0 0 10px;
              border-left: 1px solid #e3e3e3;
            }
          }
          & > div {
            background: #ffffff;
            border-top: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
            padding: 10px 16px;
            min-height: 58px;
          }
          &:last-child {
            & > div {
              border-radius: 0 10px 10px 0;
              border-right: 1px solid #e3e3e3;
            }
          }
        }
        .min_height_14 {
          padding: 7px;
        }
      }
    }
  }
  .clctn_last_flex {
    display: flex;
    align-items: center;
    button {
      padding: 0;
      height: auto;
      min-width: 34px;
      border-radius: 50%;
      margin-left: 15px;
      box-shadow: 3.09091px 6.18182px 15.4545px rgba(251, 176, 64, 0.3);
      cursor: pointer !important;
      &:hover {
        cursor: pointer !important;
        opacity: 0.8;
      }
    }
  }
}
.pagination_box {
  margin-top: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  // font-family: 'Montserrat';
  font-family: $f_secondary;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #303030;
  button {
    min-width: 28px;
    border-radius: 50%;
    padding: 0;
    margin: 0 8px;
    font-family: $f_secondary;
    width: 28px;
    height: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #373737;
    position: relative;
    transition: all 0.2s ease-out;
    background: transparent;
    cursor: pointer !important;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background: transparent;
      border: none;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border: 1px solid transparent;
        background: linear-gradient(278deg, #f8bb03, #f8bb03) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        pointer-events: none;
        border-radius: 50%;
      }
    }
    &.active {
      border: none;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border: 1px solid transparent;
        background: linear-gradient(278deg, #f8bb03, #f8bb03) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        pointer-events: none;
        border-radius: 50%;
      }
    }
  }
  p {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }
  .prew_next_p_btn {
    background: #ffffff;
    border: 1px solid #bcbcbc;
    box-shadow: 2.81818px 5.63636px 14.0909px rgba(251, 176, 64, 0.3);
    &:focus {
      box-shadow: 2.81818px 5.63636px 14.0909px rgba(251, 176, 64, 0.3);
    }
    &::before {
      display: none;
    }
    &:hover {
      background: #ffffff;
      border: 1px solid #bcbcbc;
      opacity: 0.8;
    }
  }
  .prew_p_btn {
    img {
      position: relative;
      left: -2px;
    }
  }
  .next_p_btn {
    img {
      position: relative;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      right: -2px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .userlist_table_prnt table thead tr th {
    font-size: 11px;
    padding: 12px;
  }
  .userlist_table_prnt table tbody tr td {
    font-size: 13px;
    line-height: 30px;
  }
  .userlist_table_prnt .clctn_last_flex button {
    min-width: 30px;
    margin-left: 10px;
    height: 30px;
    width: 30px;
  }
  .userlist_table_prnt table tbody tr td > div {
    background: #ffffff;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    padding: 7px 12px;
    min-height: 0px;
  }
  .user_cntnt_box {
    padding: 24px 15px 30px 15px;
    min-height: 0px;
  }
  .user_cntnt_box .user_list_top_img {
    height: 21px;
  }
  .user_cntnt_box .user_cntr_line_bg {
    bottom: 100px;
    top: 21px;
  }
  .user_cntnt_box .user_list_btm_img {
    height: 100px;
  }
}
@media screen and (max-width: 991px) {
  .usr_list_cntnr {
    padding: 0 15px 0 15px;
  }
  .userlist_page {
    padding-top: 80px;
  }
  h2.def_head_dbrd {
    font-size: 32px;
    line-height: 34px;
  }
}
@media screen and (max-width: 767px) {
  .user_cntnt_box .user_list_top_img {
    height: 14px;
  }
  .user_cntnt_box .user_list_btm_img {
    height: 66px;
    width: 100%;
  }
  .user_cntnt_box .user_cntr_line_bg {
    bottom: 66px;
    top: 14px;
  }
  .pagination_box {
    margin-top: 80px;
  }
}
@media screen and (max-width: 575px) {
  h2.def_head_dbrd {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 7px;
  }
  .user_cntnt_box {
    padding: 20px 10px 25px 10px;
  }
  .create_user_prnt_raw h5 {
    font-size: 20px;
    line-height: 20px;
    padding-top: 0;
  }
  button.creat_user_btn {
    font-size: 14px;
    img {
      margin-left: 4px;
    }
  }
  .userlist_table_prnt {
    margin-top: 12px;
  }
  .userlist_table_prnt table tbody tr td {
    font-size: 12px;
    line-height: 25px;
  }
  .userlist_table_prnt .clctn_last_flex button {
    min-width: 25px;
    margin-left: 7px;
    height: 25px;
    width: 25px;
  }
  .userlist_table_prnt table tbody tr td > div {
    padding: 5px 10px;
  }
  .userlist_table_prnt table thead tr th {
    font-size: 10px;
    padding: 10px;
  }
  .userlist_table_prnt table tbody tr td {
    padding: 2px 0;
  }
  .pagination_box {
    margin-top: 40px;
    font-size: 11px;
    button {
      width: 24px;
      height: 24px;
      min-width: 24px;
      margin: 0 4px;
      font-size: 11px;
    }
  }
}
.chakra-modal__content-container .create_new_user_popup {
  background: #ffffff !important;
  border-radius: 30px;
  max-width: 936px;
  .chakra-modal__body {
    padding: 40px 65px 45px 65px !important;
  }
}
.mdl_overlay {
  background: rgba(46, 9, 38, 0.3) !important;
  backdrop-filter: blur(15px);
  z-index: 9999 !important;
}
.chakra-modal__content-container {
  z-index: 99999 !important;
}
.create_new_user_popup {
  .chakra-modal__header {
    padding: 45px 35px 40px 35px;
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 29px;
    text-align: center;
    background: linear-gradient(317.17deg, #c96c00 10.34%, #ffa800 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    border-bottom: 1px solid #d3c4d0;
  }
  .chakra-modal__footer {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d3c4d0;
  }
  .chakra-modal__close-btn {
    left: 35px;
    top: 48px;
    height: auto;
    width: 78px;
    padding: 0;
    font-family: $f_poppins !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #303030;
    background: transparent;
    svg {
      margin-right: 8px;
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background: transparent;
    }
  }
  .chakra-modal__body {
    .mdl_body_h {
      font-family: $f_poppins !important;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #303030;
    }
  }
}
.inpt_prnt_box_wrap {
  display: flex;
  gap: 10px;
}
.inpt_prnt_box {
  border: 1px solid #f9bd05;
  border-radius: 6px;
  padding: 0 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  min-height: 49px;
  height: 49px;
  position: relative;
  p {
    position: absolute;
    opacity: 0.6;
    left: 10px;
    top: 3px;
    font-size: 9px;
  }
  input {
    margin-top: 5px;
    font-family: $f_poppins !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16.5789px;
    line-height: 17px;
    color: #303030;
    outline: none;
    background-color: transparent;
    &::placeholder {
      color: #303030;
    }
    
  }
  .chakra-form-control {
    .chakra-input {
      padding: 0;
      border: none;
      outline: none !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      font-family: $f_poppins !important;
      font-style: normal;
      font-weight: 300;
      font-size: 16.5789px;
      line-height: 17px;
      color: #303030;
      outline: none;
      background-color: transparent;
      &::placeholder {
        color: #303030;
      }
    }
    .chakra-form__label {
      margin-left: 0 !important;
      background: #ffffff !important;
      font-family: $f_poppins !important;
      font-style: normal;
      font-weight: 300;
      font-size: 16.5789px;
      color: #777777;
    }
  }
  select {
    padding: 0;
    outline: none;
    border: none;
    font-family: $f_poppins !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16.5789px;
    line-height: 17px;
    color: #303030;
    &:focus {
      box-shadow: none;
    }
    option {
      background: #fff;
      color: #303030;
    }
  }
  .chakra-select__icon-wrapper {
    width: auto;
    right: 0;
    svg {
      background: url(../img/select_arrow_ic.svg) no-repeat center;
      background-size: 8px;
      path {
        display: none;
      }
    }
  }
}
.paymnt_mthd_box {
  margin-top: 65px;
  select {
    padding: 0;
    outline: none;
    border: none;
    &:focus {
      box-shadow: none;
    }
    option {
      background: #fff;
    }
  }
  .chakra-select__icon-wrapper {
    width: auto;
    right: 0;
    svg {
      background: url(../img/select_arrow_ic.svg) no-repeat center;
      background-size: 8px;
      path {
        display: none;
      }
    }
  }
  table {
    margin-top: 20px;
    thead {
      tr {
        th {
          padding: 15px 5px;
          font-family: $f_poppins !important;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #303030;
          border: none;
          background: #f4f4f4;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 5px;
          // font-family: 'Montserrat';
          font-family: $f_secondary;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 20px;
          color: #303030;
          position: relative;
          border: none;
          & > div {
            background: #f4f4f4;
            border: 1px solid #cccccc;
            min-height: 49px;
            height: 49px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            min-width: 150px;
          }
          &:first-child {
            padding-left: 26px;
            .chakra-radio {
              position: absolute;
              left: 0;
              top: 32px;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: 0px;
                border: 1px solid transparent;
                background: linear-gradient(278deg, #ffa800, #ffa800) border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                mask-composite: exclude;
                -webkit-mask-composite: destination-out;
                pointer-events: none;
                border-radius: 50%;
              }
              &[data-checked] {
                .chakra-radio__control {
                  background: transparent;
                  box-shadow: none;
                  border: none;
                  &::before {
                    background: linear-gradient(270.07deg, #ffa800 -0.47%, #ffa800 97.06%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .add_new_pymnt_btn {
    // font-family: 'Montserrat' !important;
    margin-top: 9px;
    font-family: $f_secondary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    background: linear-gradient(317.17deg, #c96c00 10.34%, #ffa800 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0px;
      border: 1px solid transparent;
      background: linear-gradient(278deg, #ffa800, #ffa800) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      pointer-events: none;
      border-radius: 10px;
    }
    &:hover {
      background: linear-gradient(317.17deg, #c96c00 10.34%, #ffa800 81.33%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      opacity: 0.8;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.inpt_prnt_box .usd_text_aj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16.5789px;
  line-height: 17px;
  color: #303030;
  margin-left: auto;
}
.modal_footer {
  button {
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15.6461px;
    line-height: 19px;
    color: #ffffff;
    padding: 0;
    box-shadow: none !important;
  }
  .cncl_btn {
    background: url(../img/cal_btn.png) no-repeat !important;
    background-size: cover;
    width: 210px;
    height: 46px;
    margin-right: 30px;
  }
  .fill_btn {
    background: url(../img/fill_btn.png) no-repeat !important;
    background-size: cover;
    width: 210px;
    height: 46px;
  }
}
@media screen and (max-width: 575px) {
  .modal_footer {
    flex-direction: column;
    display: flex;
    .cncl_btn {
      margin-bottom: 20px;
    }
  }
  .chakra-modal__content-container .create_new_user_popup .chakra-modal__body {
    padding: 25px !important;
  }
}
.paymnt_mthd_box table tbody tr td > div input {
  background: transparent !important;
  outline: none !important;
}
.paymnt_mthd_box table tbody tr td > div .copy_btn_pernt {
  margin-left: auto;
  background: transparent;
  box-shadow: none;
}

// DASHBOARD CSS
.def_btn {
  // background: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  height: 24px !important;
  padding: 0 10px !important;
  border-radius: 4px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 10px !important;
  text-align: center !important;
  color: #ffff !important;
  @media screen and (max-width: 767px) {
    height: 22px !important;
  }
}
.Resolved_btn {
  button {
    color: #ffff;
    background: linear-gradient(273.07deg, #086453 0%, #00bb1e 101.06%);
    &:hover {
      background: linear-gradient(273.07deg, #00bb1e 0%, #086453 101.06%);
    }
  }
}
.Open_btn {
  button {
    background: linear-gradient(272.39deg, #f43e88 6.32%, #88216b 94.7%);
    // border: 1px solid #000000;
    &:hover {
      background: linear-gradient(272.39deg, #88216b 6.32%, #f43e88 94.7%);
    }
  }
}
.Progress_btn {
  button {
    background: linear-gradient(118.01deg, #4998c5 27.2%, #0c61c4 67.36%);
    &:hover {
      background: linear-gradient(118.01deg, #0c61c4 27.2%, #4998c5 67.36%);
    }
  }
}
.Closed_btn {
  button {
    background: linear-gradient(272.39deg, #444444 0%, #757575 105.89%);
    &:hover {
      background: linear-gradient(272.39deg, #757575 0%, #444444 105.89%);
    }
  }
}
.userlist_table_prnt_aj table thead tr th {
  border-bottom: none;
  padding-bottom: 0 !important;
  text-transform: capitalize;
}
.userlist_table_prnt_aj .cetner_items {
  text-align: center;
}
.userlist_table_prnt_aj .last_two_btn {
  display: flex;
  justify-content: center;
  button {
    height: auto;
    background: transparent;
    box-shadow: none;
    padding: 0;
    img {
      box-shadow: 3.09091px 6.18182px 15.4545px rgba(255, 164, 81, 0.2) !important;
      border-radius: 50%;
      @media screen and (max-width: 1199px) {
        height: 25px;
      }
      @media screen and (max-width: 767px) {
        height: 22px;
      }
    }
  }
  .margin_right {
    margin-right: 10px;
  }
}

.second_table {
  padding-top: 40px;
  padding-bottom: 30px;
  .bg_btn_pernt {
    button {
      background: url(../img/red_btn_bg.png) no-repeat;
      background-size: cover;
      box-shadow: none;
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 8.58902px;
      line-height: 10px;
      color: #ffffff;
      width: 129px !important;
      height: 36px !important;
      @media screen and (max-width: 1199px) {
        width: auto !important;
        height: 28px !important;
      }
      @media screen and (max-width: 575px) {
        width: auto !important;
        height: 22px !important;
      }
    }
  }
}
.dahboardgrid {
  display: flex;
  // align-items: flex-start;
}
.flex75 {
  display: flex;
  flex-direction: column;
  flex-basis: 51%;
}
.flex25 {
  display: flex;
  flex-basis: 49%;
  @media screen and (min-width: 1440px) {
    max-width: 49%;
  }
  flex-direction: column;
  padding-left: 5px;
}
.first_hed {
  h1 {
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    color: #303030;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase
  }
}
.foryscroll {
  overflow-y: auto;
  max-height: 770px;
}

.notiftion_sc_main {
  padding: 10px;
  @media screen and (max-width: 1600px) {
    padding: 0px;
  }
  @media screen and (max-width: 1440px) {
    padding: 10px;
  }
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
}

.notiftion_sc_main_innr_content {
  position: relative;
  margin-bottom: 8px;
  background: #ffffff;
  border-radius: 10px;
  padding: 17px 18px;
  // border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    @media screen and (max-width: 1600px) {
      font-size: 12px;
    }
  }
  .first_open_btn {
    background: linear-gradient(272.39deg, #f43e88 6.32%, #88216b 94.7%);
    &:hover {
      background: linear-gradient(272.39deg, #88216b 6.32%, #f43e88 94.7%);
    }
  }
  .progs_btn {
    background: linear-gradient(118.01deg, #4998c5 27.2%, #0c61c4 67.36%);
    &:hover {
      background: linear-gradient(118.01deg, #0c61c4 27.2%, #4998c5 67.36%);
    }
  }
  .closed_btn {
    background: linear-gradient(272.39deg, #444444 0%, #757575 105.89%);
    &:hover {
      background: linear-gradient(272.39deg, #757575 0%, #444444 105.89%);
    }
  }
  .resolved_btn {
    background: linear-gradient(273.07deg, #086453 0%, #00bb1e 101.06%);
    &:hover {
      background: linear-gradient(273.07deg, #00bb1e 0%, #086453 101.06%);
    }
  }
  .margin_pernt {
    margin: 0 30px 0 25px;
    @media screen and (max-width: 1600px) {
      margin: 0 15px 0 15px;
      font-size: 12px;
    }
  }
  .arrow_ic {
    margin: 0 11px;
  }
}
.notiftion_sc_main_innr_content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0px;
  border: 1px solid transparent;
  background: linear-gradient(278deg, #ffa451, #ffef61) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  pointer-events: none;
  border-radius: 10px;
}
.bodymain {
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1439px) {
  .dahboardgrid {
    flex-direction: column;
  }
  .flex75,
  .flex25 {
    width: 100%;
  }
  .flex25 {
    margin-top: 30px;
  }
}

// MODAL CSS
.chakra-modal__content-container .chakra-modal__content.modal_report {
  background: #ffffff !important;
  border-radius: 10px;
  // padding: 35px 23px !important;
  max-width: 1180px;
  overflow: hidden;
  .modal_body {
  }
}

.moda_hed {
  padding: 35px 26px 29px 26px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E4CAB7;
  .had_left_bx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding-left: 28px;
    &::before {
      content: "";
      position: absolute;
      top: 1px;
      right: 0px;
      bottom: 0px;
      left: 0;
      margin: 0px;
      border: 1px solid transparent;
      background: linear-gradient(270.07deg, #FF8A00 0%, #FEC600 100%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      border-radius: 14px;
      pointer-events: none;
    }
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #303030;
      flex-basis: 25%;
    }
    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #303030;
      /* margin-left: 10px; */
      display: flex;
      align-items: center;
      flex-basis: 25%;
      >img {
        margin-right: 10px !important;
      }
    }
    .submited_bxx {
      flex-basis: 25%;
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #777777;
        margin-bottom: 3px;
      }
      h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #303030;
      }
    }
    .resolved_btn_had {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      background: linear-gradient(273.07deg, #086453 0%, #00BB1E 101.06%);
      border-radius: 0px 14px 14px 0px;
      width: 149px;
      height: 55px;
      color: #FFFFFF;
      border: none;
      outline: none;
      box-shadow: none;
      &:hover {
        background: linear-gradient(273.07deg, #00BB1E 0%, #086453 101.06%);
      }
    }
  }
  
  .close_had_btnn {
    button {
      background: #E6E6E6;
      border-radius: 8px;
      width: 45px;
      margin-left: 52px;
      height: 45px;
      box-shadow: none;
      border: none;
      outline: none;
      padding: 0;
    }
  }
  .def_btn {
    color: #ffff;
    background: linear-gradient(273.07deg, #086453 0%, #00bb1e 101.06%);
    width: 149px !important;
    height: 55px !important;
    border-radius: 0px 16px 16px 0px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    /* identical to box height, or 91% */

    text-align: center;

    color: #FFFFFF;
    &:hover {
      background: linear-gradient(273.07deg, #00bb1e 0%, #086453 101.06%);
    }
  }
}
.dtls_Dscrib_poblm_main {
  padding: 29px 26px 29px 26px !important;
  border-bottom: 1px solid #e4cab7;
  .dflt_h1_nls {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
    color: #303030;
  }
  .dtls_txt {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #303030;
    margin-bottom: 13px;
  }
}
.description_solution_cont {
  padding: 24px 26px 40px 26px !important;
  border-bottom: 1px solid #e4cab7;
  p {
    max-width: inherit !important;
    &:last-child {
      margin-bottom: 25px !important;
    }
  }
}

.details_content {
  border: 1px solid #F0F0F0;
  border-radius: 6px;
  margin-right: 6px;
  padding: 17px 19px 10px 19px;
  min-height: 190px;
  .details_innnr {
    margin-bottom: 17px;
    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #777777;
    }
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
    }
  }
}
.describe_problem_cont {
  margin: 20px 0;
  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
    color: #303030;
  }
  .border_box .gredint_border::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0px;
    border: 1px solid transparent;
    background: linear-gradient(278deg, #c96c00, #ffa800) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    pointer-events: none;
    border-radius: 6px;
  }
  .border_box {
    position: relative;
    // border: 1px solid #8E4A80;
    border-radius: 6px;
    // min-height: 358px;
    padding: 15px;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      max-width: 100%;
      margin:  0;
      // &:last-child {
      //   margin-bottom: 64px;
      // }
    } 
    .last_one_p_dflt {
      margin-top: 24px;
    }
  }

}
.foter_this_modl {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 45px 0 !important;
  button {
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15.6461px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    margin-right: 38px;
    box-shadow: none !important;
    &:last-child {
      margin: 0;
    }
    &:hover {
      color: #00bb1e;
    }
  }
  .back_btn {
    background: url(../img/back_bg.png) no-repeat center;
    padding: 0;
    max-width: 212px !important;
    width: 100%;
    min-height: 47px;
    &:hover {
      background: url(../img/back_bg.png) no-repeat center;
    }
  }
  .close_btn {
    background: url(../img/close_bg.png) no-repeat center;
    padding: 0;
    max-width: 212px !important;
    width: 100%;
    min-height: 47px;
    &:hover {
      background: url(../img/close_bg.png) no-repeat center;
      color: #000000 !important;
    }
  }
}
.main_img_box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 7px;
  ul {
    display: block;
    text-align: left;
    max-width: 601px;
  }
  .main_img_box_innr {
    background: #f0f0f0;
    border-radius: 6px;
    padding: 9px 0px 9px 10px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-top: 8px;
    .flex_btn_prnt {
      display: flex;
      align-items: center;
    }
    .pdf_img {
      margin-right: 11px;
    }
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
    }
    button {
      background: transparent;
      padding: 0;
      box-shadow: none;
      height: auto;
      width: inherit;
      margin-left: 15px;
    }
  }
}
.description_solution_cont {
  .main_img_box {
    margin-top: 19px;
  }
}
@media screen and (max-width: 991px) {
  .rsopsiv_margin {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .describe_problem_cont .border_box p {
    max-width: 100%;
    margin: 0 0 15px 0;
  }
  .describe_problem_cont .border_box p:last-child {
    margin-bottom: 20px;
  }
  .foter_this_modl {
    padding: 30px 0 !important;
  }
  .description_solution_cont {
    padding: 24px 26px 26px 26px !important;
  }
}
@media screen and (max-width: 575px) {
  .foter_this_modl {
    flex-direction: column;
  }
  .foter_this_modl .back_btn {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .moda_hed h1 {
    font-size: 18px;
  }
  .moda_hed {
    padding: 23px !important;
  }
}

.sidebarlink_prnt .logout_btn {
  font-family: $f_poppins;
  font-style: normal;
  color: #303030 !important;
  background: transparent;
  padding: 0;
  box-shadow: none !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  &:hover {
    background: linear-gradient(270.07deg, #2E1209 -0.47%, #F79400 97.06%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }
}

// logout modal css

.chakra-modal__content-container .chakra-modal__content.widht_set_pernt {
  min-height: 365.37px !important;
  min-width: 570px !important;
  .modal_bg_main {
    // background: url(../img/logout_bg.png) no-repeat center !important;
    // height: 362.37px;
    // width: 570.01px;
    min-height: 362.37px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .lgout_hed {
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 31px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #303030;
      padding: 54px 0 65px 0 !important;
      z-index: 1;
    }
    .modl_body_pera {
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
      color: #303030;
    }
  }
}
.footer_modal {
  padding: 0 0 60px 0 !important;
  button {
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15.6461px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 28px 0 0 !important;
  }
  .back_button {
    background: url(../img/back_bg.png) no-repeat center !important;
    width: 208.79px;
    height: 46.56px;
  }
  .logout_btn {
    background: url(../img/close_bg.png) no-repeat center !important;
    width: 211.79px;
    height: 47px;
  }
}
.main_rightbx {
  position: relative;
  min-height: 100%;
  padding: 20px 24px 20px 24px;
  .ntfctn_tp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 112px;
  }
  .ntfctn_btm {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 180px;
  }
  .ntfctn_mddl {
    position: absolute;
    bottom: 180px;
    top: 112px;
    left: 0;
    right: 0;
    z-index: -1;
    background: url(../img/ntfctn_mddl.png) repeat-y 0 0;
    background-size: contain;
  }
  @media screen and (max-width: 575px) {
    padding: 20px 15px 20px 15px;
  }
}
.pagelogo {
  position: fixed;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  max-width: 100%;
}
@media screen and (max-width: 575px) {
  .forntfctnscroll {
    overflow-x: auto;
  }
  .forntfctnscroll_inn {
    min-width: 400px;
  }
}

.right_content_main {
  position: relative;
}
.userlist_table_prnt {
  h5 {
    font-family: $f_poppins;
    font-style: normal;
    font-size: 22px;
    line-height: 20px;
    color: #303030;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.userlist_table_prnt_ab {
  thead {
    tr {
      th {
        border-top: none !important;
      }
    }
  }
  .last_two_btn {
    .chakra-button {
      img {
        box-shadow: 3.09091px 6.18182px 15.4545px rgba(255, 164, 81, 0.2) !important;
      }
    }
  }
  .btn_hight_w {
    button {
      min-width: 114px;
      width: 100%;
      min-height: 24px;
    }
  }
  .report_list_head_bx {
    tr {
      th {
        color: #a4a4a4;
      }
    }
  }
}
.reaport_trik_inrr_bx {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-top: 25px;
}
.form_cntrl_boxv2_ab {
  padding: 29px 34px 30px 26px;
  .inpt_prnt_box {
    input {
      width: 100%;
    }
  }
}
.report_ticket_hed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4cab7;
  padding: 19px 35px 22px 23px;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #303030;
  }
}
.form_cntrl_boxv2_ab {
  .description_text_p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #303030;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #303030;
      margin-left: 5px;
    }
  }
}
.Add_files_prnt {
  display: flex;
  align-items: center;
  margin-top: 16px;
  .preview {
    max-width: 586px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    & > div {
      margin-right: 8px;
      margin-bottom: 7px;
      width: 186px;
      display: flex;
      align-items: center;
      height: 39px;
      background: #f0f0f0;
      border-radius: 6px;
      padding: 0 7px;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 8px;
        margin-right: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 120px;
      }
      button {
        margin-left: auto;
      }
    }
  }
}
.Add_files_bx {
  min-height: 82px;
  // background: linear-gradient(270.07deg, rgba(46, 9, 38, 0.05) -0.47%, rgba(168, 3, 70, 0.05) 97.06%);
  background: linear-gradient(270.07deg, rgba(255, 164, 81, 0.2) -0.47%, rgba(255, 239, 97, 0.2) 97.06%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid #f9bd05;
  max-width: 100%;
  width: 100%;
  position: relative;
  min-width: 235px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(270.07deg, #FF8A00 0%, #FEC600 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 10px;
    pointer-events: none;
  }
  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    background: linear-gradient(317.17deg, #ff8a00 10.34%, #fec600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    input {
      opacity: 0;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #818181;
    margin-top: 5px;
  }
}
.reeport_prnt_min {
  .more_ticket_def {
    background: url(../img/more_ticket_def_btn.png) no-repeat !important;
    background-size: cover !important;
    width: 225px !important;
    height: 39px !important;
    box-shadow: none !important;
    outline: none !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13.3663px;
    line-height: 15px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    margin-top: 30px !important;
    margin-bottom: 15px;
    span {
      background: linear-gradient(317.17deg, #ff8a00 10.34%, #fec600 81.33%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}
.text_ariya_box {
  textarea {
    border: 1px solid #f9bd05;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 16px;
    min-height: 372px;
    box-shadow: none !important;
    outline: none !important;
    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
    }
    &:hover {
      border: 1px solid #f9bd05;
    }
  }
}

@media screen and (max-width: 767px) {
  .mt_bx_ripon_siv {
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  .modal_footer .fill_btn {
    margin-top: 15px;
  }
  .create_user_prnt_raw_ab h5 {
    font-size: 17px;
  }
  .report_ticket_hed {
    padding: 15px;
  }
  .report_ticket_hed {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 23px 15px;
  }
  .report_ticket_hed h2 {
    font-size: 17px;
  }
  .form_cntrl_boxv2_ab {
    padding: 15px;
  }
  .userlist_table_prnt h5 {
    font-size: 17px;
  }
}

.modal_bg_main {
  position: relative;
  // background-color: #fff;
  .pop_tp_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 24px;
  }
  .pop_btm_bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 168px;
  }
  .pop_midol_bg {
    position: absolute;
    top: 23px;
    left: 0px;
    right: 0;
    bottom: 167px;
    background: url(../img/midol_ic_pooi.png) repeat-y 0 0;
    z-index: 0;
    width: 100%;
    background-size: contain;
    // background-color: #fff;
  }
}
.lgout_hed {
  color: #000000 !important;
}
.modl_body_pera {
  color: #303030 !important;
}

@media screen and (max-width: 625px) {
  .footer_modal_v2 {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px !important;
    button {
      margin: 0 !important;
      margin-bottom: 18px !important;
    }
  }
  .modal_bg_main .pop_btm_bg {
    bottom: 64px;
  }
  .chakra-modal__content-container .chakra-modal__content.widht_set_pernt {
    min-width: 295.01px !important;
  }
  .modal_bg_main .pop_btm_bg {
    bottom: 82px !important;
    height: 86px;
  }
  .chakra-modal__content-container .chakra-modal__content.widht_set_pernt .modal_bg_main .lgout_hed {
    padding-top: 35px !important;
    font-size: 20px !important;
    padding-bottom: 10px !important;
  }
  .modal_bg_main .pop_midol_bg {
    max-width: 447px !important;
  }
}
@media screen and (max-width: 767px) {
  .Add_files_prnt {
    flex-direction: column;
    .preview {
      max-width: 100%;
    }
  }
}
.reaport_trik_inrr_bx .reprt_cls_btn {
  opacity: 1;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(317.17deg, #ff8a00 10.34%, #fec600 81.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 13px 0;
  padding: 0;
  height: auto;
  min-width: 0;
  img {
    margin-left: 3px;
  }
  &:hover {
    background: linear-gradient(317.17deg, #ff8a00 10.34%, #fec600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.essue_rqst_swtch_box {
  display: flex;
  align-items: center;
  h2 {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .light_h2 {
    color: #7E7E7E;
  }
  .isue_rqst_switch {
    margin: 0 17px;
    width: 45px;
    height: 20px;
    position: relative;
    .chakra-switch__track {
      width: 45px;
      background: #DADADA;
      box-shadow: none;
      .chakra-switch__thumb {
        position: absolute;
        background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
      }
    }
    &[data-checked] {
      .chakra-switch__thumb {
        right: 13px;
      }
    }
  }
}
.ticketlist_table_prnt table tbody tr td > div.pdng_0 {
  padding-top: 0;
  padding-bottom: 0;
  height: 52px;
}
.userlist_table_prnt_as table tbody tr td > div.pdng_0 {
  padding-top: 0;
  padding-bottom: 0;
  height: 58px;
}
.userlist_table_cntnr {
  margin-top: 16px;
}
.status {
  width: 44px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 7px;
  line-height: 20px;
  color: #FFFFFF;
}
.crtcl_svrt {
  background: linear-gradient(307.59deg, #E70000 -1.89%, #FF0C55 78.59%);
}
.high_svrt {
  background: linear-gradient(317.17deg, #FF4D00 10.34%, #FE8900 81.33%);
}
.mdum_svrt {background: linear-gradient(317.17deg, #FF9900 10.34%, #FFC700 81.33%);}
.low_svrt {background: linear-gradient(317.17deg, #0094FF 10.34%, #00FEFE 81.33%);}
.ticketlist_table_prnt, .userlist_table_prnt_as {
  table thead tr th {
    font-size: 10px;
    font-weight: 400;
  } 
  table tbody tr td {
    font-size: 11px;
    line-height: 50px;
  }
  table tbody tr td > div {
    min-height: 52px;
    padding: 0px 16px;
    &.last_two_btn_last {
      padding-left: 0;
    }
  }
  .rprt_imgs_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status_btn_prnt {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    button {
      width: 102px !important;
      border-radius: 0 !important;
      font-size: 12px !important;
      transition: all 0.2s ease-out;
      height: 50px !important;
      display: flex;
    }
  }
}
.notiftion_sc_main_innr_content_as {
  min-height: 52px;
  padding: 0 0 0 18px;
  &::before {
    background: linear-gradient(278deg, rgba(255, 164, 81, 0.5), rgba(255, 239, 97, 0.5)) border-box;
  }
  .first_open_btn {
    width: 102px !important;
    height: 52px !important;
    border-radius: 0 !important;
  }
  .progs_btn {
    width: 102px !important;
    height: 52px !important;
    border-radius: 0 !important;
    background: linear-gradient(273.07deg, #086453 0%, #00BB1E 101.06%);
    &:hover {
      background: linear-gradient(273.07deg, #00BB1E 0%,  #086453 100%);
    }
  }
  .closed_btn {
    width: 102px !important;
    height: 52px !important;
    border-radius: 0 !important;
    background: linear-gradient(272.39deg, #444444 0%, #757575 105.89%);
    &:hover {
      background: linear-gradient(272.39deg, #757575 0%, #444444 105.89%);
    }
  }
  .resolved_btn {
    width: 102px !important;
    height: 52px !important;
    border-radius: 0 !important;
    background: linear-gradient(273.07deg, #086453 0%, #00bb1e 101.06%);
    &:hover {
      background: linear-gradient(273.07deg, #00bb1e 0%, #086453 101.06%);
    }
  }
}
.accuont_info_box {
  padding-top: 5px;
}
.inpt_prnt_box {
  input:not(:placeholder-shown)+label, input:focus+label {
    font-size: 12px !important;
    line-height: 17px;
    transform: scale(1) translateY(-5px) !important;
    top: -3px;
  }
  .label {
    top: 1;
    padding: 0;
  }
  .chakra-form-control .chakra-form__label {
    background: transparent !important;
    padding: 0 !important;
  }
  .chakra-form-control .chakra-input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
.accuont_info_inn {
  max-width: 420px;
  margin: 110px auto;
  .inpt_prnt_box {
    margin-top: 10px;
    background: #FFFFFF;
  }
}
.edi_btn_prnt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 0 25px 0;
  .edt_btn {
    background: url(../img/fill_btn.png) no-repeat !important;
    background-size: cover;
    width: 210px;
    height: 46px;
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15.6461px;
    line-height: 19px;
    color: #FFFFFF;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.btm_inputs {
  .inpt_prnt_box {
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
  }
}
.cancel_sv_btn_prnt {
  margin: 0 -17px;
  justify-content: space-between;
  .cncl_btn {
    background: url(../img/cancl_btn_bg.png) no-repeat !important;
  }
  .sve_btn {
    background: url(../img/save_btn_bg.png) no-repeat !important;
  }
}
@media screen and (max-width: 1439px) {
  .main_rightbx {
    min-height: 431px;
  }
  .accuont_info_inn {
    margin: 90px auto;
  }
}
@media screen and (max-width: 991px) {
  .accuont_info_inn {
    margin: 60px auto;
  }
}
@media screen and (max-width: 575px) {
  .accuont_info_inn {
    margin: 40px auto;
  }
  .edi_btn_prnt {
    padding: 25px 0 15px 0;
  }
  .cancel_sv_btn_prnt {
    flex-direction: column;
    margin: 0;
    .sve_btn {
      margin-top: 10px;
    }
  }
}
.userlist_table_prnt_as {
  table tbody tr td > div {
    min-height: 58px;
    padding: 0px 16px;
    &.last_two_btn_last {
      padding-left: 0;
      justify-content: center;
    }
  }
  .status {
    height: 56px;
  }
  table tbody tr td {
    font-size: 14px;
    line-height: 56px;
  }
  .status_btn_prnt button {
    height: 56px !important;
  }
}
.text_align_center {text-align: center !important;}
.bg_btn_pernt {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebarlink_prnt a.log_out_link {
  background: transparent !important;
  -webkit-text-fill-color: #303030;
}
.moda_hed_as {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 87px;
  padding-right: 0 !important;
}
.tcket_right_btn {
  button{
    width: 152px !important;
    height: 87px !important;
    font-weight: 500 !important;
    font-size: 17.8824px !important;
    line-height: 15px !important;
    color: #FFFFFF;
    border-radius: 0px 10px 0px 0px !important;
  }
}
.tcket_hed_inn {
  display: flex;
  align-items: center;
  .img_txt_h {
    display: flex;
    align-items: center;
    // margin-left: 40px;
    img {
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 767px) {
  .tcket_hed_inn .img_txt_h {
    // margin-left: 25px;
    img {
      margin-right: 5px;
    }
  }
}
@media screen and (max-width: 575px) {
  .tcket_hed_inn {
    flex-direction: column;
    align-items: flex-start;
    .img_txt_h {
      margin-top: 10px;
      margin-left: 0;
    }
  }
  .tcket_right_btn button {
    width: 130px !important;
    font-size: 12.8824px !important;
  }
}


.inpt_prnt_slct_nl {
  label {
    font-family: $f_poppins;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
  }
  .css-b62m3t-container {
    border: 1px solid #f9bd05;
    border-radius: 6px;
    padding: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    min-height: 49px;
    height: 49px;
    position: relative;
    width: 100%;
    background: transparent;
  }
  .df_select__control {
    background: transparent;
    width: 100%;
    border: none;
    height: 49px;
    box-shadow: none;
    outline: none !important;
  }
  .df_select__single-value  {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
    color: #000000;
  }
  .df_select__indicator-separator {
    display: none;
  }
  .df_select__menu-list  {
    background: black;
    border: 1px solid #f9bd05;
    border-radius: 6px;
    padding: 10px 0;
  }
  .css-9gakcf-option {
    background: url(../img/true_slct.png) no-repeat 96% 8px;
  }
  .css-1n7v3ny-option:hover {
    background-color: transparent !important;
  }
  .css-1n7v3ny-option:focus {
    background-color: transparent !important;
  }
  .df_select__option--is-focused {
    background-color: transparent !important;
  }
  .df_select__option {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 14px 25px;
    border-bottom: 1px solid #402039;
    &:last-child {
      border: none;
    }
  }
  .chakra-form-control .chakra-input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000 !important;
    &::placeholder {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #000000 !important;
    }
  }
  .dsxyaY, .sc-bcXHqe {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #000000;
    
  }
  .bUDnBe {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    font-size: 16.5789px;
    padding-left: 0px;
  }
}
.df_select__menu {z-index: 9 !important;}
.moda_hed_asv2 {
  padding: 23px 25px !important;
  .had_close_btn {
    background: #66305B;
    border-radius: 8px;
    height: 45px;
    min-width: 45px;
    margin-left: 52px;
    padding: 0;
    box-shadow: none !important;
    outline: none;
    border: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #53304c;
    }
  }
}
.tcket_hed_inn {
  width: 100%;
  padding: 0 0 0 28px;
  border-radius: 14px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    border: 1px solid transparent;
    background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 19.693px;
    pointer-events: none;
  }
  .ticket_had_txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
    flex-basis: 25%;
  }
  .img_txt_h {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    flex-basis: 25%;
  }
  .submited_bx {
    flex-basis: 25%;
    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #777777;
    }
    h4 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
  .tcket_right_btn {
    flex-basis: 25%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.dtls_Dscrib_poblm_main {
  .dtl_txt_had {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 12px;
  }
}
.describe_problem_cont {
  .gredint_border {
    padding: 8 20px;
    textarea {
      border: transparent;
      box-shadow: none;
      outline: none;
      padding: 0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}


@media screen and (max-width: 991px) {
  .moda_hed_asv2 .had_close_btn {
    margin-left: 28px;
  }
  .tcket_hed_inn {
    padding: 0 0 0 12px;
  }
}

@media screen and (max-width: 768px) {
  .moda_hed_asv2 {
    padding: 13px 15px !important;
  }
  .moda_hed .def_btn {
    width: 118px !important;
    height: 50px !important;
  }
  .tcket_hed_inn .ticket_had_txt, .tcket_hed_inn .img_txt_h {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 575px) {
  .tcket_hed_inn .ticket_had_txt ,.tcket_hed_inn .img_txt_h, .tcket_hed_inn .submited_bx,  .tcket_hed_inn .tcket_right_btn 
  {flex-basis: 100%;}
  .moda_hed_asv2 .had_close_btn {
    margin-left: 10px;
  }
  .tcket_hed_inn {
    padding: 12px !important;
    justify-content: center;
    align-items: center;
  }
  .tcket_hed_inn .tcket_right_btn {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 16px !important;
  }
  .moda_hed .def_btn:hover {
    width: 100% !important;
    border-radius: 16px !important;
  }
  .submited_bx{
    margin: 5px 0 !important;
    p{
      text-align: center;
    }
  }
}
// AJIT
.btn_and_hed{
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 12px 80px 12px;
  a{
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
    padding: 7px 48px;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border: 2px solid transparent;
      background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      pointer-events: none;
    }
    span{
      margin-left: 8px;
    }
    &:hover{
      background: linear-gradient(270.07deg, #cfa328 -0.47%, #db6800 97.06%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  h1{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1400px) {
  .btn_and_hed{
    padding: 0 12px 50px 12px;
  }
}
@media screen and (max-width: 991px) {
  .btn_and_hed{
    padding: 0 12px 25px 12px;
  }
  .btn_and_hed h1{
    font-size: 30px;
    line-height: 32px;
  }
}
@media screen and (max-width: 767px) {
  .btn_and_hed{
    padding: 30px 12px 0px 12px;
  }
  .btn_and_hed h1 {
    font-size: 23px;
    line-height: 25px;
    margin: 0 0 0 auto !important;
   
  }
}
@media screen and (max-width: 400px) {
  .btn_and_hed {
    padding: 15px 12px 0px 12px;
  }
  .btn_and_hed h1 {
    font-size: 18px;
    line-height: 22px;
  }
  .btn_and_hed a{
    font-size: 16px;
    line-height: 19px;
    padding: 7px 23px;
  }
  .btn_and_hed a span {
    margin-left: 4px;
  }
}

.lft_part_grid_bx{
  background: #F8F8F8 !important;
  padding-left: 38px;
  padding-right: 28px;
  padding-top: 1px;
  padding-bottom: 70px;
  .details_content{
    background: #FFFFFF;
    margin-right: 0 !important;
  }
}
.dtls_Dscrib_poblm_main_v2a{
  padding: 0 !important;
}
.details_text{
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: #303030;
  margin-top: 24px !important;
  margin-bottom: 18px !important;
}
.moda_hed_ab{
  // border: 1px solid #E4CAB7 !important;
}
.details_text_bx{
  background: #FFFFFF;
  border: 1px solid #C9C9C9;
  border-radius: 6px;
  padding: 15px 17px 30px 20px;
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
  }
}
.foter_this_modl_ab{
  .sp_btnv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 35px;
    .fourer_two_btn{
      display: flex;
      align-items: center;
      button{
        width: 215px;
      }
    }
  }
}
.remove_ticket_btn{
  background: url(../img/remove_ticket.png) no-repeat 0 !important;
  background-size: cover !important;
  width: 212px;
  align-items: center;
  height: 45px;
  svg{
    margin-right: 5px;
  }
  &:hover{
    opacity: 0.9;
    color: #000000 !important;
  }
}
.files_part{
  .main_img_box{
    ul{
      li{
        background: #fff;
        width: 48%;
      }
    }
  }
}
.discussion_border{
  h4{
    padding-left: 19px;
  }
}
.client_box_min{
  border-top: 1px solid #D6D6D6 !important;
}
.client_box_mini{
  margin-left: 19px;
  margin-top: 16px;
  margin-bottom: 12px;
  width: 122px;
  height: 32px;
  background: #EFEFEF;
  border-radius: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #303030;
    text-align: center;
  }
  img{
    position: absolute;
    left: 0;
    top: 0;
  }
}
.textarea_prnt{
  margin-left: 48px;
  max-width: 610px;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  border: 1px solid rgba(201, 108, 0, 1);
  border-radius: 6px;
  padding: 13px 50px 8px 15px;
}
.two_btv_wer{
  display: flex;
  justify-content: end;
  padding-right: 25px;
  margin-top: 15px;
  margin-bottom: 24px;
  .add_files{
    background: url(../img/logout_bg022.png) no-repeat 0;
    background-size: cover;
    width: 170px;
    &:hover{
      background: url(../img/logout_bg022.png) no-repeat 0;
      background-size: cover;
      width: 170px;
    }
  }
  .logout_bg022_grin{
    background: url(../img/logout_bg022_grin.png) no-repeat 0;
    background-size: cover;
    width: 170px;   
    margin-left: 15px;
    &:hover{
      background: url(../img/logout_bg022_grin.png) no-repeat 0;
      background-size: cover;
      width: 170px;   
    }
  }
  button{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 12.6216px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    &:hover{
      color: #000000;
    }
    box-shadow: none !important;
  }
}
.two_btv_wer_v2{
  display: contents;
  .main_img_box{
    margin-left: 62px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
.client_box_mini_v2{
  width: 145px;
  padding-left: 10px;
}
.client_box_mini_spbtn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #303030;
    margin-right: 25px;
  }
}
.two_text_p{
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    max-width: 545px;
    margin-left: 62px;
    margin-right: 50px;
  }
  .two_text_p_two{
    margin-top: 20px;
  }
}

.foter_this_modl_ab{
  border-top: 1px solid #D6D6D6 !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}
.tick_srol_bx_in_deve{
  overflow-x: auto;
  height: 615px;
}
@media screen and (max-width: 1200px) {
  .textarea_prnt{
    max-width: 510px;
    padding: 13px 20px 8px 15px;
  }
}
@media screen and (max-width: 1000px) {
  .textarea_prnt {
    margin-left: 20px;
  }
  .two_text_p p {
    margin-left: 20px;
    margin-right: 20px;
  }
  .two_btv_wer_v2 .main_img_box {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 4px;
  }
}
@media screen and (max-width: 1077px) {
  .tick_srol_bx_in_deve{
    height: 780px;
  }
}
@media screen and (max-width: 991px) {
  .tick_srol_bx_in_deve{
    height: 350px;
  }
  .files_part .main_img_box ul li{
    width: inherit;
  }
  .two_btv_wer{
    display: block;
    padding-left: 20px;
  }
  .two_text_p p{
    max-width: 100% !important;
  }
  .two_text_p .two_text_p_two {
    margin-top: 10px;
  }
  .two_btv_wer_v2 .main_img_box {
  margin-left: 0px;
  }
  .textarea_prnt{
    max-width: 540px;
  }
}
.remove_ticket_btn{
  height: 45px !important;
}
@media screen and (max-width: 780px) {
  .foter_this_modl_ab .close_btn {
    background-size: 160px;
    font-size: 14px;
    margin-right: 9px;
  }
  .fourer_two_btn{
    button{
      width: 160px !important;
    }
  }
  .remove_ticket_btn{
    background-size: 160px !important;
    width: 160px !important;
    font-size: 13px !important;
  }
}
@media screen and (max-width: 600px) {
  .foter_this_modl_ab .sp_btnv {
    flex-direction: column;
  }
  .foter_this_modl button{
    margin-right: 0 !important;
  }
  .foter_this_modl_ab .sp_btnv .fourer_two_btn{
    margin-top: 10px;
    button{
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .lft_part_grid_bx {
    background: #F8F8F8 !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 20px;
  }
  .foter_this_modl_ab .sp_btnv {
    padding: 0 20px;
  }
  .foter_this_modl {
    padding: 10px 0 !important;
  }
  .textarea_prnt {
    max-width: 370px;
  }
  .details_text_bx{
    padding: 10px;
  }
}
@media screen and (max-width: 400px) {
  .two_btv_wer .logout_bg022_grin{
    margin-left: 0;
    margin-top: 5px;
  }
  .two_btv_wer{
    flex-direction: column;
    display: flex;
  }
  .foter_this_modl_ab .sp_btnv .fourer_two_btn{
    flex-direction: column;
    margin-top: 5px;

    button{
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
  .textarea_prnt {
    max-width: 290px;
    padding: 5px 10px 8px 10px;
}
}
.files_part .main_img_box ul li{
  width: inherit !important;
}
.cusrtm_pargf p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #303030;
  // width: 169px !important;
  background: #ffffff;
  word-break: break-word;
  white-space: normal;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  min-height: 52px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1440px) {
    width: 100% !important;
  }
  @media screen and (max-width: 1100px) {
    width: 250px !important;
  }
}
.cusrtm_pargf div {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #303030;
  width: 169px !important;
  background: #ffffff;
  word-break: break-word;
  white-space: normal;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  min-height: 52px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1440px) {
    width: 100% !important;
  }
  @media screen and (max-width: 1100px) {
    width: 250px !important;
  }
}


.RemoveTicketModal{
  max-width: 570px !important;
  box-shadow: none !important;
}
.pop_min_box{
  position: relative;
  .pop_dat_box{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .remov_h{
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 31px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #303030;
      margin-top: 55px;
      margin-bottom: 55px;
      text-transform: uppercase;

    }
    .remov_p{
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
      color: #303030;
      width: 339px;
      margin: 0 auto;
      margin-bottom: 64px;
    }
  }
}
.pop_to_btn{
  display: flex;
  margin: 0 auto;
}
.def_cancel_ab{
  font-family: $f_poppins;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15.6461px !important;
  line-height: 19px !important; 
  text-align: center;
  color: #FFFFFF;
  background: url(../img/Cancel_btn_bg.png) no-repeat center !important;
  background-size: cover !important;
  height: 46px !important;
  width: 210px;
  box-shadow: none !important;
  outline: none !important;
  margin-right: 28px !important;
  &:hover{
    opacity: 0.8;
  }
}
.def_remove_ab{
  box-shadow: none !important;
  outline: none !important;
  font-family: $f_poppins;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15.6461px !important;
  line-height: 19px !important;
  text-align: center;
  color: #FFFFFF;
  background: url(../img/remov_btn_bg.png) no-repeat center !important;
  background-size: cover !important;
  width: 212px;
  height: 46.56px !important;
  &:hover{
    opacity: 0.8;
  }
}
@media screen and (max-width: 600px) {
  .pop_min_box .pop_dat_box .remov_h{
    font-size: 20px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .pop_min_box .pop_dat_box .remov_p{
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .pop_min_box .pop_dat_box .remov_p {
    margin-bottom: 10px;
    width: 289px;
    font-size: 12px;
    line-height: 20px;
  }
  .pop_to_btn{
    flex-direction: column;
  }
  .def_cancel_ab{
    margin-right: 0 !important;
    margin-bottom: 0px;
  }
  .pop_min_box{
    width: 310px !important;
    margin: 0 auto !important;
  }
  .pop_min_box .pop_dat_box .remov_h {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 9px;
  }
  .def_remove_ab{
    background-size: 160px !important;
    font-size: 12px !important;
  }
  .def_cancel_ab{
    background-size: 160px !important;
    font-size: 12px !important;
  }
}



// nl


.user_cntnt_box_new {
  padding: 36px 35px 35px 20px;
}
.header_client_rpt_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #D6D6D6;
  padding-bottom: 20px;
  .dflt_h3_ttl {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 20px;
    color: #303030;
    position: relative;
    top: 1px;
    font-size: 22px;
    font-weight: 600;
  }
}
.right_datepkr {
  display: flex;
  align-items: center;
  .space_btwn {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 10px;
    text-align: center;
    color: #303030;
    margin-left: 3px;
    margin-right: 5px;
    font-weight: 600;
    font-size: 10px;
  }
  .space_btwn_to {
    margin: 0 7px;
  }
  .date_bx {
    input {
      background: #E8E8E8;
      border-radius: 8px;
      padding: 5px 8px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      line-height: 10px;
      text-align: center;
      color: #303030;
      border: none;
      box-shadow: none;
      outline: none;
      text-align: start;
    }
  }
}
.frst_right_dtepkr {
  display: flex;
  align-items: center;
}
.gnrl_rport_bx {
  .gnrl_rpt_btn {
    background: url(../img/fill_btn_new.png) no-repeat !important;
    background-size: cover !important;
    width: 210px;
    height: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 11.3684px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 24px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .stng_btn_head {
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    box-shadow: 0px 8px 20px rgb(255 255 255 / 30%);
    border-radius: 0;
    margin-left: 22px;
    width: 55px;
    min-height: 34px;
    border: none;
    outline: none;
    svg {
      color: #fff;
    }
    &:hover {
      background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    }
    &:focus {
      box-shadow: 0px 8px 20px rgb(255 255 255 / 30%);
    }
  }
}


@media screen and (max-width: 767px) {
  .header_client_rpt_list {
    flex-direction: column;
  }
  .gnrl_rport_bx button {
    margin-left: 15px;
  }
}

@media screen and (max-width: 575px) {
  .right_datepkr {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
  }
  .gnrl_rport_bx {
    text-align: start;
    margin-left: 0;
  }
  .gnrl_rport_bx button {
    text-align: start;
    margin-left: 0;
    margin-top: 12px;
  }
 
}
@media screen and (max-width: 380px) {
  .right_datepkr .date_bx input {
    width: 100%;
    max-width: 100px;
  }
  .gnrl_rport_bx .stng_btn_head {
    margin-left: 0;
    width: 45px;
  }
}

// table
.mt-0 {margin-top: 0 !important;}
.table_client_list {
  margin-top: 5px;
  .client_tbl_frst {
    width: 50%;
  }
  .client_tbl_last_th {
    width: 10%;
  }
  .tbl_clnt_head {
    tr {
      th {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 10px;
        color: #303030;
        text-transform: capitalize;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
  .pd-0 {
    th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .pt-0 {
    th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: 0;
    }
  }
  .table_body {
    tr {
      // position: relative;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   margin: 0;
      //   border: 1px solid transparent;
      //   background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
      //   -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      //   mask-composite: exclude;
      //   -webkit-mask-composite: destination-out;
      //   border-radius: 19.693px;
      //   pointer-events: none;
      //   margin-bottom: 8px;
      // }
      .frst_td_nl {
        width: 50%;
        border-left: 1px solid #fec000;
        border-radius: 10px 0 0 10px;
      }
      .last_td_nl {
        border-right: 1px solid #fec000;
        border-radius: 0 6px 6px 0px;
      }
      td {
        // width: 10%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: #303030;
        border-top: 1px solid #fec000;
        border-bottom: 1px solid #fec000;
          button, a {
            background: url(../img/tbl_btn_bg11.png) no-repeat !important;
            background-size: cover;
            width: 91px !important;
            height: 25px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 11.3684px;
            line-height: 17px;
            text-align: center;
            color: #FFFFFF;
            margin-left: 24px;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              opacity: 0.8;
            }
          }
      }
    }
  }
}
.withShadow {
  tbody {
    tr {
      box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
      border-radius: 10px;
    }
  }
}
.traslt {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: 0;
}

.table_client_list {
  overflow-y: auto;
}


// reportdetail

// modal css

.chakra-modal__content-container .chakra-modal__content.new_modele_setting {
  background: #FFFFFF !important;
  border-radius: 10px;
  margin-top: 100px !important;
  width: 100%;
  min-height: 642px;
  max-width: 547px;
  button.back_btn_header {
    padding: 0;
  }
  .chakra-modal__header {
    padding: 26px 24px;
  }
  .mdl_rprt_client {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      max-width: 300px;
      color: #303030;
      margin-bottom: 54px;
    }
  }
  .chckbx_modl_in {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    label {
      background: #E8E8E8;
      border-radius: 6px;
      width: 231px;
      height: 47px;
      padding-left: 12px;
      margin-bottom: 8px;
      input:checked + .chakra-checkbox__control {
        background: #000;
      }
      .chakra-checkbox__control {
        background: #FFFFFF;
        border: 1px solid #818181 !important;
        border-radius: 4px;
        width: 20px;
        height: 20px;
        box-shadow: none;
        outline: none;
        position: relative;
        
          &::before {
            content: "";
            position: absolute;
            background: url(../img/true_arrow.svg) no-repeat center;
            background-size: 11px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 1;
            z-index: 1;
            width: 100%;
            height: 100%;
          }
        svg {
          color: transparent;
          display: block;
        }
        
      }
      .chakra-checkbox__label {
        margin-left: 22px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #303030;
      }
    }
  }
  .chakra-modal__footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.last_mdl_two_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 40px 10px;
  .cncl_btn_mdl {
    background: url(../img/cancl_btn_bg.png) no-repeat !important;
    background-size: cover;
    width: 210px !important;
    height: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 11.3684px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 28px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .cnfrm_btn_mdl {
    background: url(../img/cnfrm_bg.png) no-repeat !important;
    background-size: cover;
    width: 210px !important;
    height: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 11.3684px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width:575px) {
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting {
    max-width: 300px;
  }
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting .chakra-modal__header {
    padding: 26px 10px;
  }
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting .mdl_rprt_client h2 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
  }
  .last_mdl_two_btns {
    flex-direction: column;
  }
  .last_mdl_two_btns .cncl_btn_mdl {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
// modal css 


.had_txt_back_btn {
  display: flex;
  align-items: center;
}
button.back_btn_header, a.back_btn_header {
  background: transparent;
  border: none;
  outline: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #303030;
  margin-right: 52px;
  outline: none !important;
  border: none;
  box-shadow: none;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background: transparent;
  }
  svg {
    margin-right: 8px;
  }
}
@media screen and (max-width: 1340px) {
  button.back_btn_header, a.back_btn_header {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1280px) {
  .header_client_rpt_list {
    flex-direction: column;
    align-items: flex-start;
  }
  
}
@media screen and (max-width: 768px) {
  .right_datepkr_v2 {
    flex-direction: column;
  }
  .gnrl_rport_bx_v2 {
    margin-top: 12px;
    .gnrl_rpt_btn {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 575px) {
  button.back_btn_header, a.back_btn_header {
    font-size: 14px;
  }
  .user_cntnt_box_new {
    padding: 20px;
  }
}



.report_detl_tbl {
  .detl_tbl_main {
    border-collapse: separate;
    thead {
      tr {
        th {
          &:first-child {
            width: 80%;
          }
          width: 10%;
        }
      }
    }
  }
}
.client_report_tbl {
  border-spacing: 0 15px;    
  border-collapse: separate !important;
}

@media screen  and (max-width:991px) {
  .table_client_list .table_body tr td {
    font-size: 14px;
  }

}
@media screen  and (max-width:575px) {
  .gnrl_rport_bx .gnrl_rpt_btn {
    margin-left: 0;
  }
}
.pagination_box_new {
  .prew_next_p_btn {
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 16%);
    border-radius: 6px;
    border: none;
  }
  button {
    border: 1px solid #DBDBDB;
    border-radius: 6px;
  }
  button:hover::before {
    border-radius: 6px;
    background: linear-gradient(270.07deg, #FF8A00 -0.47%, #FEC60097.06%);
  }
}


// reprt
.dflt_thead_tbl {
  .main_head_client_tbl {
    th {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;
      color: #303030;
      text-transform: inherit;
    }
  }
  .main_head_client_tbl th{
    background: transparent;
    border: transparent;
  }
  tr {
    th {
      background: #E8E8E8;
      border-top: 1px solid #373737;
      border-bottom: 1px solid #373737;
    }
  }
}

.bg_traprnt {
  th {
    border-bottom: transparent;
  }
}
.dflt_bg_tr {
  background: #E8E8E8;
  th {
    border-top: 1px solid #373737;
    border-bottom: 1px solid #373737;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #303030 !important;
    width: 10%;
  }
 
  .client_tbl_frst {
    border-left: 1px solid #373737;
    border-radius: 10px 0 0 10px;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 14px !important;
    color: #303030 !important;
    width: 80%;
  }
  .last_tbl_report_th {
    border-right: 1px solid #373737;
    border-radius: 0 10px 10px 0;
  }
}
.table_client_list_report {
 .table_body {
  tr {
    background: #FFFFFF;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 25%);
    border-radius: 10px;
    .last_bdy_td_dflt {
      border-right: 1px solid #fec000;
      border-radius: 0px 10px 10px 0px;
    }
  }
 }
}
.table_body_swpcl_tbl {
  tr {
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  .frst_td_nl {
    .span_prjct_grdant {
      background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 17px;
    }
  }
  tr {
    td {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13.8542px;
      line-height: 12px;
      color: #303030;
      span {
        font-weight: 700;
        font-size: 16.1632px;
        line-height: 19px;
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .header_client_rpt_list .dflt_h3_ttl {
    font-size: 15px;
  }
  .table_body_swpcl_tbl tr td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .table_client_list .tbl_clnt_head tr th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .dflt_bg_tr th, .dflt_bg_tr .client_tbl_frst {
    font-size: 14px !important;
  }
  .table_body_swpcl_tbl .frst_td_nl .span_prjct_grdant {
    font-size: 16px;
  }
  .table_body_swpcl_tbl tr td span {
    font-size: 14px;
  }
}

.only_lvl_tbl {
  @media screen and (max-width: 991px) {
    height: 370px;
  }
  .frst_tr_bg_clr {
    background: #E8E8E8 !important;
    border: none;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .pd_12 td {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .table_body tr td {
    border: transparent;
    font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;
      color: #303030;
  }
  .table_body tr {
    .frst_td_nl {
      border-left: 1px solid transparent;
      width: 15%;
      border-radius: 0;
    }
    .third_40width {
      width: 40%;
    }
    // td {
    //   width: 15%;
    // }
  }
}
.table_client_list_report {
  .table_bodytemlate_2 {
    .inr_body_tr_2 {
      background: transparent;
      box-shadow: none;
      td {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #303030;
        border-bottom: 1px solid #D6D6D6;
        h3 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #303030;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
            width: 10px;
          }
        } 
        .lvl_1_bx {
          background: linear-gradient(317.17deg, rgb(255 138 0 / 20%) 10.34%, rgb(254 198 0 / 20%) 81.33%);
          /* opacity: 0.2; */
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          padding: 6px 8px;
          span {
            background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            /* color: #FF8A00; */
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
          }
        }
      }
    }
    .inr_body_tr_2_as {
      td {
        padding-top: 0 !important;
      }
    }
  }
}


.acrdian_new_panl {
  margin-top: 18px;
  .chakra-accordion__item {
    border: 1px solid #FEC600;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    // position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   margin: -1px;
    //   border: 1px solid transparent;
    //   background: linear-gradient(270.07deg, #ffa451 -0.47%, #ffef61 97.06%);
    //   -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    //   mask-composite: exclude;
    //   -webkit-mask-composite: destination-out;
    //   border-radius: 10px;
    //   pointer-events: none;
    // }
    h2 {
      .prjct_txt_btn {
        background: #fff;
        border: none;
        outline: none;
        border-radius: 10px;
        box-shadow: none;
        padding-top: 14px;
        padding-bottom: 14px;
        .prjct_clr_bg {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          color: #000;
        }
      }
    }
    .chakra-accordion__panel {
      padding: 0;
      .table_client_list {
        margin-top: 0;
        .client_report_tbl {
          border-spacing: 0;
        }
        
      } 
    }
  }
  
  .right_acrdin_header {
    display: flex;
    align-items: center;
    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13.8542px;
      line-height: 12px;
      color: #303030;
      margin-right: 40px;
      span {
        font-weight: 700;
        font-size: 16.1632px;
        line-height: 19px;
        margin-left: 5px;
      }
    }
    button {
      margin-left: 40px;
      position: relative;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;
      color: #303030;
      border: 1px solid transparent;
      box-shadow: none;
      outline: none;
      background: transparent;
      border-radius: 60px;
      img{
        margin-left: 16px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border: 1px solid transparent;
        background: linear-gradient(280.07deg, #FF8A00 -0.47%, #FEC600 56%);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        border-radius: 60px;
        pointer-events: none;
      }
    }
  }
}
.acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn[aria-expanded="false"] .accArrow{
  transform: rotate(180deg);
}
.client_report_tbl_task_only {
  border-spacing: 0;
} 
// .only_lvl_tbl .table_body .frst_tr_bg_clr_project_1accdian td {
//   width: 10%;
// }
.only_lvl_tbl .table_body .frst_tr_bg_clr_project_1accdian td.width_onl_80 {
  width: 80%;
}
.cntnrt_psn_46 {
  position: relative;
  top: 46px;
}
@media screen and (max-width: 1280px) {
  .acrdian_new_panl .right_acrdin_header h3 {
    margin-right: 10px;
  }
  .acrdian_new_panl .right_acrdin_header button {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .acrdian_new_panl .table_body_swpcl_tbl tr td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .cntnrt_psn_46 {
    top: 26px;
  }
}
@media screen and (max-width: 767px) {
  .acrdian_new_panl .right_acrdin_header h3 {
    display: none;
  }
}
@media screen and (max-width: 380px) {
  .acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn{
    padding: 10px;
  }
  .acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn .prjct_clr_bg {
    font-size: 16px;
  }
}
.prjct1_2mix .width_onl_70 {
  width: 70% !important;
}
.prjct_2_item {
  margin-top: 18px !important;
}


.acrdian_new_panl .paddding_58 {
  padding: 18px 0 0 58px !important;
}
// .acrdian_new_panl .only_lvl_tbl {
//   height: auto;
//   overflow-y: inherit;
// }
.task_tr_new {
  background: #E8E8E8;
  // display: flex;
  // align-items: center;
  // td {
  //   &:nth-child(2) {
  //     width: 80% !important;
  //   }
  // }
}
.only_lvl_tbl .table_body .task_nme_nmbr {
  background: transparent !important;
  border-bottom: 1px solid #D6D6D6;
 td {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303030;
  &.weight400 {
    font-weight: 400;
  }
  label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #303030;
    margin-bottom: 8px;
  }
 }
}
.only_lvl_tbl .table_body .astopbrdr {
  border-top: 1px solid #D6D6D6;
}
// @media screen and (max-width: 1599px) {
//   .task_tr_new td:nth-child(2) {
//     width: 73% !important;
//   }
//   .only_lvl_tbl .table_body .frst_tr_bg_clr_project_1accdian td {
//     width: 13%;
//   }
// }
@media screen and (max-width: 1440px) {
  .acrdian_new_panl .chakra-accordion__item .chakra-accordion__panel .table_client_list {
    padding: 18px 0 0 0 !important;
  }
  // .task_tr_new td:nth-child(2) {
  //   width: 54% !important;
  // }
  // .only_lvl_tbl .table_body .frst_tr_bg_clr_project_1accdian td {
  //   width: 24%;
  // }
}
@media screen and (max-width: 1280px) {
  .only_lvl_tbl .table_body .task_nme_nmbr td {font-size: 12px;}
}
@media screen and (max-width: 1199px) {
  .client_report_tbl_task_only {
    min-width: 850px !important;
  }
  .only_lvl_tbl .table_body .task_nme_nmbr td {
    font-size: 12px;
  }
}


.only_lvl_tbl .task_last_tbl .task_nme_nmbr td.fnt_wtg_400 {font-weight: 400;}

.prjct_txt_btn[aria-expanded="true"] .hide_btn_new span.show_dtl{
  display: none;
}
// .acrdian_new_panl .chakra-accordion__button[aria-expanded="true"] {}
// .prjct_txt_btn[aria-expanded="true"] .hide_btn_new span:last-child{
//   display: block;
// }
.prjct_txt_btn[aria-expanded="false"] .hide_btn_new span:first-child{
  display: none;
}

.forg_btn_pop{
  font-family: $f_poppins;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent !important;
  margin: 0 auto;
  outline: none !important;
  border: none !important;
  display: flex !important;
  margin-top: 22px;
  justify-content: center;
  transition: all 0.2s ease-out;
  height: auto !important;
  box-shadow: none !important;
  &:hover{
    opacity: 0.6;
  }
}
.rese_btn_box{
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  background-size: contain !important;
}
.res_grin_p{
  font-family: $f_poppins;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 26px !important;
  text-align: center;
  letter-spacing: -0.03em !important;
  color: #D117E0;
  margin-top: 100px;
}
.txt_cntnt{
  p{
    text-transform: uppercase;
  }
}
.inpt_bx_v2_alt{
  .input_box{
    input{
      border: red 1px solid !important;
    }
  }
}
.opps_red_p{
  font-family: $f_poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #FF4E4E;
  margin-top: -9px;
}
.darkgrin_btn_img{
  background: transparent url(../img/darkgrin_btn_img.png) no-repeat center !important;
    font-family: $f_secondary;
    font-weight: 500;
    font-size: 15.6461px;
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    width: 210px;
    height: 46px !important;
    transition: all 0.2s ease-out;
    box-shadow: none !important;
    margin-right: 15px !important;
    &:focus,
    &:hover {
      color: #bb36ff;
      box-shadow: none;
      border: none;
      background: transparent url(../img/darkgrin_btn_img.png) no-repeat center !important;
    }
}
.rese_btn_box_v2{
  margin-top: 50px;
}
.rese_btn_box_v2c{
  .pop_login_btn{
    color: #ffff;
    margin-right: 15px;
  }
}
@media screen and (max-width: 630px) {
  .rese_btn_box{
    button{
      background-size: contain !important;
      font-size: 14px !important;
      width: 190px;
    }
  }
  .rese_btn_box_v2 {
    margin-top: 35px;
  }
  .rese_btn_box_v2c{
    margin-top: 65px;
  }
}
@media screen and (max-width: 500px) {
  .forg_btn_pop{
    margin-top: 11px;
  }
  .rese_btn_box{
    flex-direction: column;
  }
  .rese_btn_box {
    margin-top: 20px;
    button{
      margin-bottom: 12px;
    }
  }
  .res_grin_p {
    font-size: 17px !important;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .darkgrin_btn_img{
    margin-right: 0 !important;
    width: 145px !important;
    margin-bottom: 2px !important;
  }
  .rese_btn_box_v2{
    .pop_login_btn{
     font-size: 11px !important; 
    }
  }
  .rese_btn_box_v2 {
    margin-top: 0px;
  }
  .inpt_bx_v2_alt_eis{
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 380px) {
  .rese_btn_box{
    button{
      margin-bottom: 8px;
      background-size: contain !important;
      font-size: 13px !important;
      width: 170px;
    }
  }
  .opps_red_p {
    font-size: 11px !important;
    margin-top: -14px;
  }
  .forg_btn_pop {
    margin-top: 4px !important;
    font-size: 11px !important;
  }
  .inpt_bx_v2_alt_eis{
    margin-top: 4px !important;
  }
  .rese_btn_box_v2c{
    margin-top: -18px;
  }
}

// repor_mod_min_bx
// repor_mod_min_bx
.repor_mod_min_bx{

}
.chakra-modal__content-container .chakra-modal__content.report_mod_contin{
  background: #FFF !important;
  padding-bottom: 120px;
}
.report_mod_contin{
  max-width: 1060px !important;
}
.top_riport_P_img_bx{
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.repor_mod_head{
  padding: 25px 26px 9px 25px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;  
  position: relative;
  .sidebar_logo{
    max-width: 313px;
    height: 49.93px;
  }
  .top_riport_P{
    font-family: $f_poppins;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    margin-top: 13px;
  }
}
.rport_data_bx{
  position: relative;
  background: linear-gradient(119.31deg, rgb(255 255 255 / 30%) 24.44%, rgb(139 139 139 / 30%) 99.63%);
  margin-top: 10px;
  padding-top: 60px;
  padding-bottom: 40px;
  .riport_pop_bxtp_img{
    position: absolute;
    left: 0;
    top: -5px;
    right: 0;
    width: 100%;
  }
  .riport_pop_bxbtm_img{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}
.rport_data_bx_contin{
  display: flex;
  justify-content: space-between;
  .rport_data_lft_contin{
    h3{
      font-family: $f_poppins;
      font-weight: 700;
      font-size: 22px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 15px;
    }
  }
}
.adrs_flex_bx{
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  &:last-child{
    margin-bottom: 0px;
  }
  span{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 14.1049px;
    line-height: 17px;
    color: #71215C;
    margin-right: 8px;
    margin-left: 9px;
    white-space: nowrap;
  }
  p{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 14.1049px;
    line-height: 17px;
    color: #000;
  }
}
.rport_data_righ_data{  
  margin-right: 32px;
  margin-top: -15px;
  .dat_tip_p{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 5px;
  }
}
.rport_data_lft_contin{
  margin-left: 120px;
}
.def_dat_flx{
  span{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #71215C;
    margin-right: 7px;
    margin-left: 9px;
  }
  p{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000;
  }
}
.min19 {min-width: 19px;}
.dat_v2e_btm{
  .def_dat_flx span, p{
    font-weight: 700;
    font-size: 17.6444px;
    line-height: 21px;
  }
  .def_dat_flx{
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-top: 15px;
  }
}
.tbl_box_min_swap{
  padding: 0 20px;
  margin-top: 20px;
  .swap_had_P{
    font-family: $f_poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 17px;
    color: #000000;
    padding: 15px 20px;
    // background: linear-gradient(270.07deg, rgba(255, 164, 81, 0.3) -0.47%, rgba(255, 239, 97, 0.3) 97.06%);
    background: linear-gradient(270deg, rgba(255, 164, 81, 0.3) 24.44%, rgba(255, 239, 97, 0.3) 99.63%);
    // border: 1px solid #6C4C1C;
  }
}

.tbl_box_min_swap{
    .t_had_bx{
      tr{
        th{
          font-family: $f_poppins;
          font-style: normal ;
          font-weight: 500 !important;
          font-size: 16px !important;
          line-height: 17px !important;
          color: #000000;
          text-transform: capitalize;
        }
      }      
    }  
}
.tbl_box_min_swap{
  .t_body{
    tr:nth-child(even) {
      // background: linear-gradient(270.07deg, rgba(255, 164, 81, 0.3) -0.47%, rgba(255, 239, 97, 0.3) 97.06%) !important;
      // background: linear-gradient(270deg, rgba(255, 164, 81, 0.3) 24.44%, rgba(255, 239, 97, 0.3) 99.63%);
      background: #fff;
    }
  }
  .t_body{
    tr:nth-child(odd) td{
      background: transparent!important;
    }
  }
}
.tbl_box_min_swap{
  .hight_34{
    td{
      height: 34px;
      font-family: $f_poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.tbl_box_min_swap{
  .hight_64{
    td{
      height: auto;
      font-family: $f_poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      padding-top: 8px;
      padding-bottom: 8px;
      b {font-weight: bold;}
    }
  }
}
.asTble td b {font-weight: 800;}
.brd_tbl{
  border: 1px solid #6C4C1C;
  .chakra-table__container {
    // background: #ffffff;
    background: linear-gradient(270deg, rgba(255, 164, 81, 0.3) 24.44%, rgba(255, 239, 97, 0.3) 99.63%);
  }
}
.txt_end{
  text-align: end;
}
.hight_34, .hight_64 td{
  border-top: 1px solid #6C4C1C !important;
  border-right: 1px solid #6C4C1C !important;
  border-bottom: 1px solid #6C4C1C !important;
}
.hight_34, .hight_64 td:last-child{border-right: none !important;}
.hight_34 td{
  border: 1px solid #6C4C1C !important;
}
.tbl_box_min_swap .t_had_bx tr th{
  border: 1px solid #6C4C1C !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
}
.txt_end{
  width: 50%;
  text-align: right;
}
.tabl_ffoter_bx{
  tr{
    th{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      text-align: right;
      color: #000000;
      border: 1px solid #6C4C1C;
    }
  }
}
.txt_end{
  text-align: end !important;
}

.tbl_box_min_swap .t_body .last_foter:nth-of-type(odd) {
  background: #EBEBEB !important;
}
.report_mod_contin{
  position: relative;
  .pop_sedow_bot{
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none !important;
  }
  .pop_sedow_top{
    position: absolute;
    right: 0;
    pointer-events: none !important;
  }
}
.dev_report_img{
  position: absolute;
  left: 0;
  top: 0;
  height: 486px;
  width: 467px;
}
.repor_mod_head{
  .client_p{
    // font-family: $f_poppins;
    font-family: "Kallisto";
    font-weight: 700;
    font-size: 28px;
    line-height: 17px;
    color: #71215C;
    span{
      color: #000000;
    }
  }
}
.brd_tbl{
  .t_had_bx{
    background: #fff;
  }
  .t_fot_bx{
    background: #EBEBEB;
  }
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #FEC600 !important;
}

@media screen and (max-width: 991px) {
  .rport_data_righ_data {
    margin-right: 20px;
    margin-top: 0px;
  }
  .rport_data_lft_contin {
    margin-left: 20px;
  }
  .adrs_flex_bx p{
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .repor_mod_head .client_p span{
    font-size: 22px;
  }
  .tbl_box_min_swap .swap_had_P{
    font-size: 20px;
    padding: 20px 20px;
  }
  .tbl_box_min_swap .hight_64 td{
    height: 54px;
  }
  .chakra-modal__content-container .chakra-modal__content.report_mod_contin{
    padding-bottom: 50px;
  }
  .rport_data_bx_contin{
    flex-direction: column;
  }
  .rport_data_bx{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .rport_data_righ_data {
    margin-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
  }
  .repor_mod_head .sidebar_logo {
    max-width: 230px;
  }
  .repor_mod_head .top_riport_P {
    font-size: 20px;
    margin-top: -2px;
  }
  .repor_mod_head {
    padding: 5px 26px 5px 25px !important;
  }
  .adrs_flex_bx {
    align-items: baseline;
  }
}
@media screen and (max-width: 575px) {
  .repor_mod_head {
    display: flex;
    align-items: end;
    position: relative;
    height: 110px;
  }
  .tbl_box_min_swap .hight_64 td {
    height: 46px;
  }
  .tbl_box_min_swap .t_had_bx tr th{
    font-size: 14px !important;
  }
  .adrs_flex_bx_2v p{
    display: inline-block;
    width: 185px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .rport_data_lft_contin {
    margin-right: 10px;
  }
  .repor_mod_head .sidebar_logo {
    max-width: 190px;
  }
  .repor_mod_head .top_riport_P {
    font-size: 18px;
    margin-top: -10px;
  }
  .rport_data_righ_data .dat_tip_p {
    font-size: 18px;
    margin-bottom: 4px;
  }
  .dat_v2e_btm .def_dat_flx span, .dat_v2e_btm p {
    font-size: 12px;
  }
  .top_riport_P_img_bx {
    justify-content: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 20px;
    top: 0;
  }
}
.alignEnd {
  align-items: flex-end;
}
.right_datepkr_v2 {
  min-width: 612px;
  padding-left: 10px;
}
.newRhead {
  width: 60%;
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #303030;
    padding-left: 20px;
  }
  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 14px;
    color: #303030;
    background: rgba(232, 232, 232, 0.61);
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 1400px) {
  .newRhead h3 {font-size: 18px;}
}
@media screen and (max-width: 1280px) {
  .newRhead {
    width: 100%;
    margin-bottom: 12px;
  }
  .right_datepkr_v2 {
    min-width: inherit;
    padding-left: 0px;
  }
}

// invoices
// invoices
// invoices
// invoices
.invoice_min_bx{
  .def_head_dbrd{
    margin-bottom: 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.right_date_btn{
  display: flex;
  align-items: center;
  button{
    margin-left: 40px !important;
  }
}
.invoce_def_tb{
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  align-items: center;
  width: max-content;
  background: #2b2b2b;
  margin-left: 170px;
  margin-top: -65px;
  li{
    list-style: none;
    &:last-child{
      button{
        border-right: none;
      }      
    }
    &:first-child{
      button{
        border-right: 1px solid #484848;
      }      
    }
    button{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #FFFFFF;
      background: transparent;
      border-radius: 0;
      padding-left: 32px;
      padding-right: 32px;
      width: 100px;
      border-right: 1px solid #484848;    
      border-radius: 6px;
      outline: none !important;
      box-shadow: none !important;
      &:hover{
        color: black;
      }
    }
  }
}
.all_btn_invoce{
  button{
      background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%) !important;
  }
}
.paid_btn_invoce{
  button{
    background: linear-gradient(272.28deg, #00CA40 0%, #D6FE1C 100%) !important;
  }  
}
.paid_reviw_invoce{
  button{
    background: linear-gradient(272.28deg, #00CA40 0%, #D6FE1C 100%) !important;
  }
}
.paid_rejract_invoce{
  button{
      background: linear-gradient(283.78deg, #FF2E2E 0%, #FFA370 96.48%) !important;
  }
}
.invoc_tab{
  margin-bottom: 24px;
}
.chakra-checkbox.def_check_box{
  span[data-focus] {
    box-shadow: none !important;
    outline: none !important;
  }
  span[data-checked]{
    background: transparent;
    color: #000;
    border: 1px solid #D4D4D4;
  }
  .chakra-checkbox__control{
    height: 22px;
    border-radius: 4px;
    width: 22px;
    border: 1px solid #D4D4D4;
  }
}
.INvoices_min_tabl_box{
   table thead tr th{
    border: none !important;
  }
}
.for_grin_color{
  color: #00C840 !important;
}
.align_centar{
  text-align: center !important;
}
.for_totl_color{
  color: #272727 !important;
  font-weight: 600 !important;
}
.pendin_bg_color{
  background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%) !important;
}
.Paid_bg_color{
  background: linear-gradient(272.28deg, #00CA40 0%, #D6FE1C 100%) !important;
}
.Rejected{
  background: linear-gradient(283.78deg, #FF2E2E 0%, #FFA370 96.48%) !important;
}
.chakra-button.dol_sin_btn{
  background: linear-gradient(270.07deg, #FFA451 -0.47%, #FFEF61 97.06%) !important;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16) !important;
  border-radius: 12.3636px !important;
  height: 34px !important;
  width: 34px;
}
.clctn_last_flex_ab{
  button{
    background: transparent !important;
    box-shadow: none !important;
    &:first-child{
      margin-left: 0;
    }
  }
}
.userlist_table_prnt .clctn_last_flex_ab {
  display: flex;
  align-items: center;
  border-left: 1px solid #B9B9B9;
  margin-left: 10px;
  padding-left: 20px;
}
.userlist_table_prnt table tbody tr td .for_bord_riad{
  border-left: 1px solid #e3e3e3;
  border-radius: 5px 0 0 5px;
}
.for_mrg{
  margin-left: 16px;
  margin-top: 7px;
}
.chakra-heading.invos_head{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #272727;
  margin: 0 auto;
}
.Invoice_in_ab{
  .moda_hed {
    padding: 25px 26px 25px 26px !important;
    border: none !important;
  }
}
.on_xdc_tabl{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #272727;
  padding-left: 23px;
  border-top: 1px solid #EFEFEF;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input_min_bx_wi{
  max-width: 842px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
}
.inout_prnt_bx{
  margin-bottom: 16px;
  span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #272727;
    margin-bottom: 7px;
  }
  input{
    background: #EFEFEF;
    border-radius: 6px;
    height: 36px;
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    padding-left: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272727;
    &::placeholder{
      color: #272727;
    }
  }
}
.input_min_bx_wi{
  display: flex;
  padding-bottom: 20px;
}
.for_set_gri{
  width: 100%;
}
.megn_lf_20{
  margin-left: 18px;
}
.rejact_pink_btn{
  background: url(../img/rejact_pink_btn.svg) no-repeat center !important;
  &:hover{
    color: #000 !important;
  }
}
.rejac_cnc_btn_bx{
  display: flex;
  width: 100%;
  .back_btn{
    margin-right: 0;
  }
  .rejact_pink_btn{
    margin-left: 20px;
  }
}
.invoice_foot{
  padding: 22px 75px !important;
}
.Invoice_in_ab{
  .for_max_wi{
    td{
      border: none !important;
    }
  }
  .width_onl_10, .gridcell, .gridcell{
    border: none !important;
  }
}
.invos_tbl_ab{
  .task_nme_nmbr .gridcell{
    border: none !important;
    border-bottom: none !important;
  }
}
.brd_non{
  border: none !important;
}
.chakra-modal__content.modal_report.Metamask_pop_contin{
  max-width: 480px !important;
}
.Metamask_pop_body{
  .meta_mask_bo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
    margin-bottom: 18px;
    img{
      margin-right: 10px;
    }
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #000;
    }
  }
  .merta_contin_p{
    padding: 0 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000;
    max-width: 345px;
    margin: 0 auto;
  }
}
.metamask_foot{

  .chakra-button.back_btn{
    width: 178.02px !important;
    font-size: 14px !important;
    background-size: contain !important;
  }
}
.metamask_foot{
  .chakra-button.close_btn{
    width: 300.02px !important;
    font-size: 14px !important;
    background-size: contain !important;
  }
}
.foter_this_modl.metamask_foot{
  padding: 15px 55px!important;
}
.chakra-modal__content.modal_report.Payment_Confirmationab{
  max-width: 621px;
}
.Payment_Confirmationab{
  .moda_hed{
    display: flex;
    flex-direction: column;
    padding: 25px 26px 22px 26px !important;
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #272727;
      margin-top: 9px;
    }
  }
}
.chakra-button.bax_copy_btn_pernt{
  height: auto;
  padding: 0;
  width: auto;
  min-width: inherit;
  background: transparent;
  margin-left: 4px;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.Payment_Confirmationab{
  .client_report_tbl_task_only {
    min-width: inherit !important;
  }
  .invoice_foot {
    padding: 22px 85px !important;
  }
}
.paid_invo{
  .rejac_cnc_btn_bx {
    display: flex;
    width: 100%;
    margin: 0 auto;
    display: contents;
  }
}
.Metamask_pop_body{
  
  .rejact_rdd_bx{
    border-top: 1px solid #FF4949;
    border-bottom: 1px solid #FF4949;
    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      max-width: inherit;
      margin: 0;
      text-align: start;  
      margin-bottom: 60px;
      margin-top: 9px;
    }
  }
}
.ple_provi_p{
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600 !important;
  color: #000;
  padding: 0 5px;
}
.color_red{
  color: #FF4949 !important;
}
.for_rejacr_invo{
  flex-direction: column;
  >p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13.4807px;
    line-height: 28px;
    text-align: center;
    color: #000;
  }
}
.for_rejacr_invo_two_btn{
  display: flex;
  margin-top: 18px;
}



@media screen and (max-width: 1500px) {
  .invoce_def_tb {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .chakra-button.dol_sin_btn {
    height: 30px !important;
  }
  .for_mrg {
    margin-left: 10px;
    margin-top: 7px;
  }
}
@media screen and (max-width: 800px) {
  .invoice_foot {
    padding: 22px 15px !important;
  }
}
@media screen and (max-width: 700px) {
  .metamask_foot{
    .rejac_cnc_btn_bx{
      margin-bottom: 0;
    }
    .rejac_cnc_btn_bx{
      width: auto;
      margin-right: 15px;
    }
  }
  .invoice_foot{
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
  .rejac_cnc_btn_bx {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    .back_btn{
      margin-bottom: 0 !important;
    }
  }
  .for_rejacr_invo{
    .close_btn{
      min-height: inherit;
    }
  }
}
.RejectInvoTwo_foot{
  .rejac_cnc_btn_bx {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
}
.Reject_reason_bx{
  max-width: 840px;
  margin: 0 auto;
  padding: 0 20px;
  .reject_text_era{
    margin-bottom: 15px;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #FF4949 !important;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    resize: none;
    &:focus{
      outline: none !important;
      box-shadow: none !important;
    }
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;  
    margin-bottom: 10px;
  }
}
.right_datepkr .space_btwn{
  margin-top: 5px;
}
.activeBord{
  td{
    >div{
      border-color: #f9ab04 !important;
    }
  }
  
}





@media screen and (max-width: 600px) {
  .right_date_btn {
    flex-direction: column;
    align-items: self-start;
    .gnrl_rport_bx .gnrl_rpt_btn{
      margin-left: 0 !important;
      margin-top: 20px;
      width: 170px;
      background-size: contain !important;
      height: 36px;
    }
  }
  .input_min_bx_wi {
    flex-direction: column;
  }
  .megn_lf_20 {
    margin-left: 0;
  }
  .metamask_foot{
    flex-direction: row !important;
  }
  .for_rejacr_invo{
    flex-direction: column !important;
  }
}
@media screen and (max-width: 500px) {
  .Payment_Confirmationab{
   
    .invoice_foot {
      padding: 22px 5px !important;
    }
  }
  .metamask_foot{
    flex-direction: column !important;
    margin-top: 10px;
    .rejac_cnc_btn_bx{
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .metamask_foot .chakra-button.close_btn {
    width: 172.02px !important;
  }
  .invoce_def_tb li button{
    width: inherit;
    padding-left: 11px;
    padding-right: 11px;
  }
  .rejac_cnc_btn_bx {
    flex-direction: column;
    align-items: center;
  }
  .rejact_pink_btn{
    margin-top: 15px !important;
  }
  .for_rejacr_invo{
    .rejact_pink_btn{
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .rejac_cnc_btn_bx{
    margin-bottom: 0 !important;
    
  }
  .for_rejacr_invo_two_btn {
    margin-top: 18px;
    flex-direction: column;
    .close_btn{
      margin-top: 10px !important;
    }
  }
}
.txt_cntntas {
  margin-top: 95px !important;
  margin-bottom: 0px;
}