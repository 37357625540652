body.chakra-ui-dark.dashboard_pages {
  background-color: #000000 !important;
}
.chakra-ui-dark {
  .sidebarlink_prnt a span::before {
    display: none;
  }
  .light_to_darkbtn_prnt button {
    color: #ffffff;
    span::before {
      left: auto;
      right: 3px;
    }
  }
  .sidebarlink_prnt a {
    color: #ffffff;
  }
  .sidbar_main .mbl_menu_togle_btn {
    background: #705125;
    color: #fff;
    border: none;
  }
  .user_cntr_line_bg {
    background: url(../img/user_list_cntr_brdr_bg_dark.png) repeat-y 0 0;
    background-size: contain;
  }
  .create_user_prnt_raw h5 {
    color: #ffffff;
  }
  button.creat_user_btn {
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .userlist_table_prnt table thead tr th {
    color: #ffffff;
    border-bottom: 0.5px solid rgba(57, 57, 57, 0.5);
    border-top: 0.5px solid rgba(57, 57, 57, 0.5);
  }
  .userlist_table_prnt table tbody tr td > div {
    // border: none;
    background: transparent;
    position: relative;
    color: #ffffff;
    border-top: 1px solid #f9bd05;
    border-bottom: 1px solid #f9bd05;
  }
  .userlist_table_prnt table tbody tr td:first-child > div {border-left: 1px solid #f9bd05;}
  .userlist_table_prnt table tbody tr td:last-child > div {border-right: 1px solid #f9bd05;}
//   .userlist_table_prnt table {
//     border-collapse: separate;
//     border-spacing: 8px;
//     tbody tr {
//       &.border_befr_none {
//         &::before {
//             display: none !important;
//         }
//       }
//       position: relative;
//       border-radius: 10px;
//       &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         margin: -1px;
//         border: 1px solid transparent;
//         background: linear-gradient(278deg, #DB0A5F, #FFEF61 ) border-box;
//         -webkit-mask: linear-gradient(#fff 0 0) padding-box,
//           linear-gradient(#fff 0 0);
//         mask-composite: exclude;
//         -webkit-mask-composite: destination-out;
//         pointer-events: none;
//         border-radius: 10px;
//       }
//       td:first-child > div {
//         border: none;
//       }
//       td:last-child > div {
//         border: none;
//       }
//       td > div {
//         border: none;
//       }
//     }
//   }
  .pagination_box button {
    color: #ffffff;
    &:hover {
      &::before {
        background: linear-gradient(278deg, #FFEF61, #ffef61) border-box;
      }
    }
  }
  .pagination_box button.active::before {
    background: linear-gradient(278deg, #FFEF61, #FFEF61) border-box;
  }
  .pagination_box .prew_next_p_btn {
    border: 1px solid rgba(103, 103, 103, 0.5);
    background: transparent;
    img {
      filter: invert(100%);
    }
  }
  h2.def_head_dbrd {
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span {
      background: linear-gradient(317.17deg, #fff 10.34%, #fff 81.33%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .mbl_header.scrolled {
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 25px #000;
  }
  .chakra-modal__content-container .create_new_user_popup{
    background: #2E0926 !important;
  }
  .create_new_user_popup .chakra-modal__header{
    border-bottom: 1px solid #8E4A80;
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .create_new_user_popup .chakra-modal__close-btn{
    color: #fff;
  }
  .create_new_user_popup .chakra-modal__body .mdl_body_h{color: #fff !important;}
  .inpt_prnt_box input{color: #fff !important;}
  .inpt_prnt_box input::placeholder{color: #fff !important;}
  .inpt_prnt_box select{ 
    color: #fff;
      &:focus {
        box-shadow: none;
      }
      option {
        background: #481A3E;
        color: #fff;
      }
  }
  .paymnt_mthd_box table thead tr th{
    background: #000000;
    color: #fff;
   }
   .paymnt_mthd_box table tbody tr td > div{
    background: #481E3F;
    border: 1px solid #8E4A80;
    color: #fff;
   }
   .paymnt_mthd_box table tbody tr td:first-child .chakra-radio::before{
    background: linear-gradient(278deg, #FFA451, #FFEF61) border-box;
   }
   .paymnt_mthd_box table tbody tr td:first-child .chakra-radio[data-checked] .chakra-radio__control::before{
    background: linear-gradient(278deg, #FFA451, #FFEF61) border-box;
   }
   .paymnt_mthd_box .add_new_pymnt_btn{
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
   }
   .paymnt_mthd_box .add_new_pymnt_btn::before{
    // background: linear-gradient(270.07deg, #DB0A5F -0.47%, #FFEF61 97.06%) !important;
    background: linear-gradient(278deg, #f9bd05, #f9bd05) border-box;
   }
   .modal_footer{
    .fill_btn{
        background: url(../img/cal_btn.png) no-repeat !important;
    }
  }
  .paymnt_mthd_box table tbody tr td > div input::placeholder{color: #fff;}
  .inpt_prnt_box .usd_text_aj{ color: #ffffff;}
  .userlist_table_prnt_aj table thead tr th{color: #A4A4A4; border-bottom: none;}
  .first_hed h1 {color: #ffff;}
  .chakra-modal__content-container .chakra-modal__content.modal_report{
    background: #2E0926 !important;
  }
  .moda_hed h1{color: #FFFFFF;}
  .details_content h4{    color: #FFFFFF;}
  .details_content .details_innnr h2{ color: #FFFFFF;}
  .describe_problem_cont h4{color: #FFFFFF;}
  .dtls_Dscrib_poblm_main{border-bottom: 1px solid #8E4A80;}
  .describe_problem_cont .border_box{border: 1px solid #8E4A80;}
  .describe_problem_cont .border_box .gredint_border::before{display: none;}
  .describe_problem_cont .border_box p{ color: #FFFFFF;}
  .description_solution_cont{border-bottom: 1px solid #8E4A80;}
  .main_img_box .main_img_box_innr{ background: #481A3E;}
  .main_img_box .main_img_box_innr p{color: #FFFFFF;}
  .sidebarlink_prnt .logout_btn{
    color: #ffffff !important;
    &:hover {
      background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      background-clip: text !important;
      text-fill-color: transparent !important; 
    }
  }
  .chakra-modal__content-container .chakra-modal__content .modal_bg_main .lgout_hed{color: #ffffff;}
  .chakra-modal__content-container .chakra-modal__content .modal_bg_main .modl_body_pera{color: #fff;}
  // .chakra-modal__content-container .chakra-modal__content .modal_bg_main{background: url(../img/logout_bg_dark.png) no-repeat center !important; }
  .notiftion_sc_main_innr_content{background: transparent;}
  .notiftion_sc_main_innr_content h6{color: #fff;}
  .copyright_bx {
    color: #FFFFFF;
  }
  .main_rightbx {
    .ntfctn_mddl {
      background: url(../img/ntfctn_mddl_dark.png) repeat-y 0 0;
      background-size: contain;
    }
  }




  .reaport_trik_inrr_bx{
    background: #2E0926;
  }
  .report_ticket_hed h2{color: #fff;}
  .report_ticket_hed{border-bottom: 1px solid #8E4A80;}
  .form_cntrl_boxv2_ab .description_text_p{color: #fff; span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    color: #fff;
    margin-left: 5px;
  }}
  .text_ariya_box textarea{color: #fff; &::placeholder{color: #fff;}}
  .userlist_table_prnt h5{color: #fff;}
  .userlist_table_prnt_ab .last_two_btn .chakra-button img{ 
    box-shadow: 3.09091px 6.18182px 15.4545px rgba(255, 164, 81, 0.2), 0px 6.18182px 15.4545px rgba(219, 10, 95, 0.2) !important;
  }


  .lgout_hed{
    color: #fff !important;
  }
  .modal_bg_main{
    background-color: black;
  }
  .modal_bg_main .pop_midol_bg{
    background-color: black;
  }
  .modl_body_pera {
    color: #fff !important;
  }

  .modal_bg_main{
    position: relative;
    .pop_midol_bg{
      position: absolute;
      top: 23px;
      left: 0px;
      right: 0;
      bottom: 167px;
      background: url(../img/light_midol_bg.png)repeat-y 0 0;
      z-index: 0;
      width: 100%;
      background-size: contain;
    }
  }
  .Add_files_prnt .preview > div { background: #481A3E; }
  .essue_rqst_swtch_box .light_h2 {color: #818181;}
  .ticketlist_table_prnt, .userlist_table_prnt_as{.rprt_imgs_box img { filter: invert(100%); }} 
  .ticketlist_table_prnt table tbody tr td > div, .ticketlist_table_prnt table tbody tr td:first-child > div, .ticketlist_table_prnt table tbody tr td:last-child > div {
    border-color: rgba(254, 198, 0, 0.4);
  }
  .inpt_prnt_box {
    background: transparent !important;
    .chakra-form-control .chakra-form__label {
      background: transparent !important;
      color: #777777;
    }
  } 
  .btm_inputs .inpt_prnt_box { border: 1px solid #616161; }
  .cancel_sv_btn_prnt .sve_btn {
    background: url(../img/fill_btn.png) no-repeat !important;
  }
  .sidebarlink_prnt a:hover, .sidebarlink_prnt a.active  {
    background: linear-gradient(317.17deg, #FF8A00 10.34%, #FEC600 81.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .sidebarlink_prnt a.log_out_link {-webkit-text-fill-color: #ffffff !important;}
  .tcket_hed_inn .submited_bx h4{color: #fff;}
  .lft_part_grid_bx {  background: #2a1325 !important;}
  .details_text{color: #fff;}
  .lft_part_grid_bx .details_content {
    background: #2f0926;
  }
  .details_text_bx{
    background: #300926;
    border: 1px solid #8E4A80;
  }
  .details_text_bx p{
    color: #fff;
  }
  .textarea_prnt{background: transparent; color: #fff;}
  .two_text_p p{
    color: #fff;
  }
  .client_box_min {
    border-top: 1px solid #8E4A80 !important;
  }
  .foter_this_modl_ab{
    border-top: none;
  }
  .moda_hed {
    border-bottom: 1px solid #8E4A80;
  }
  .client_box_mini_spbtn h2{color: #c7c7c7;}
    .client_box_mini p {
      color: #ffe9e9;
    }
    .client_box_mini {background: #6f6b6b;}
}


// light_midol_bg
.chakra-ui-dark {
  .dtls_Dscrib_poblm_main .dtls_txt {
    color: #fff;
  }
  .moda_hed .had_left_bx h2 {
    color: #fff;
  }
  .moda_hed .had_left_bx .submited_bxx p {
    color: #777777;
  }
  .moda_hed .had_left_bx .submited_bxx h4 {
    color: #fff;
  }
  .details_content {
    border: 1px solid #8E4A80;
  }
  .inpt_prnt_slct_nl .bUDnBe {
    color: #777777;
  }
  .chakra-form-control .chakra-input {
    color: #ffffff !important;
    &::placeholder {
      color: #ffffff !important;
    }
  }
  .dsxyaY, .sc-bcXHqe {
    color: #777777;
  }
  .df_select__single-value  {
    color: rgb(255, 255, 255);
  }
  .cusrtm_pargf p {
    color: #ffffff;
    background: #000000;
    border-color: rgba(254, 198, 0, 0.4);
  }
  .cusrtm_pargf div {
    color: #ffffff;
    background: #000000;
    border-color: rgba(254, 198, 0, 0.4);
  }
  .pop_min_box .pop_dat_box .remov_h{
    color: #fff;
  }
  .pop_min_box .pop_dat_box .remov_p{
    color: #fff;
  }
}


.chakra-ui-dark {
  .header_client_rpt_list .dflt_h3_ttl {
    color: #ffffff;
  }
  .right_datepkr .space_btwn {
    color: #ffffff;
  }
  .right_datepkr .date_bx input {
    background: #2B2B2B;
    color: #FFFFFF;
  }
  .table_client_list .tbl_clnt_head tr th {
    color: #ffffff !important;
  }
  .table_client_list .table_body tr td {
    color: #ffffff;
  }
  .pagination_box_new button {
    border: 1px solid #373737;
  }
  .pagination_box_new .prew_next_p_btn {
    border: transparent;
    background: #000000;
    box-shadow: 0px 10px 30px rgb(255 255 255 / 25%);
  }
}

.chakra-ui-dark {
  button.back_btn_header, a.back_btn_header {
    color: #ffffff;
  }
  .gnrl_rport_bx .stng_btn_head svg {
    color: #000;
  }
  .dflt_bg_tr {
    background: #141414;
  }
  .table_client_list_report .table_body tr {
    background: rgb(0 0 0 / 70%) ;
  }
  .only_lvl_tbl .frst_tr_bg_clr {
    background: #141414;
  }
  .table_client_list_report .table_bodytemlate_2 .inr_body_tr_2 td h3 {
    color: #ffffff;
  }

}
.chakra-ui-dark {
  .acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn {
    background: #000;
  }   
  .acrdian_new_panl .right_acrdin_header h3 {
    color: #fff;
  }
  .acrdian_new_panl .right_acrdin_header button {
    color: #fff;
  }
  .table_client_list_report .table_bodytemlate_2 .inr_body_tr_2 td {
    border-bottom: 1px solid #2b2b2b;
  }
  .only_lvl_tbl .table_body .task_nme_nmbr {
    border-bottom: 1px solid #2B2B2B;
  }
}

// modal 
.chakra-ui-dark {
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting {
    background: #2E0926 !important;
  }
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting .mdl_rprt_client h2 {
    color: #fff;
  }
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting .chckbx_modl_in label {
    background: #6D2E60;
  }
  .chakra-modal__content-container .chakra-modal__content.new_modele_setting .chckbx_modl_in label .chakra-checkbox__label {
    color: #fff;
  }
  .task_tr_new, .frst_tr_bg_clr_project_1accdian {
    background: #141414 !important; 
  }
  .only_lvl_tbl .table_body .frst_tr_bg_clras {
    background: #141414 !important;
  }
  .header_client_rpt_list {
    border-bottom: 0.5px solid #393939;
  }
  .only_lvl_tbl .table_body .astopbrdr {
    border-top: 1px solid #2B2B2B;
  }
  .brd_tbl .chakra-table__container {
    background: #000000;
  }
}

// report_modal
.chakra-ui-dark{
  .fordbgclr {background: rgba(0, 0, 0, 1) !important;}
  .chakra-modal__content.report_mod_contin {
    background: rgba(0, 0, 0, 1) !important;
  }
  .repor_mod_head .top_riport_P, .adrs_flex_bx p{
    color: #fff;
  }
  .rport_data_righ_data .dat_tip_p, .def_dat_flx p{
    color: #fff;
  }
  .tbl_box_min_swap .swap_had_P, .tbl_box_min_swap .hight_34 td, .tbl_box_min_swap .hight_64 td, .tbl_box_min_swap .t_had_bx tr th{
    color: #fff;
  }
  .rport_data_bx{
    
    background: linear-gradient(270deg, rgba(46, 9, 38, 0.3) 24.44%, rgba(168, 3, 70, 0.3) 99.63%);
  }
  .tbl_box_min_swap .t_body .last_foter:nth-of-type(odd){
    background: #2C2C2C !important;
  }
  .repor_mod_head .client_p, .adrs_flex_bx span, .def_dat_flx span{
    color: #FBB040;
  }
  .repor_mod_head .client_p span, .rport_data_bx_contin .rport_data_lft_contin h3{
    color: #FFF;
  }
 
  .brd_tbl .t_had_bx {
    background: #000;
  }
  .brd_tbl{
    .t_fot_bx{
      background: #2C2C2C;
    }
  }
  .daterangepicker, .daterangepicker .calendar-table {
    background-color: #000 !important;
  }
  .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #fff3 !important;
  }
  .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #fff3 !important;
  }
  .acrdian_new_panl .chakra-accordion__item h2 .prjct_txt_btn .prjct_clr_bg {
    color: #fff;
  }
  .newRhead {
    label {
      color: #fff;
    }
    h3 {
      color: #fff;
      background: #141414;
      border: 1px solid #373737;
    }
  }
}

// invoices
// invoices
// invoices
// invoices
.chakra-ui-dark{
  .userlist_table_prnt table tbody tr td .for_bord_riad{
    border-left: 1px solid #f9bd05;
  }
  .chakra-checkbox.def_check_box .chakra-checkbox__control {
    background: #d4d4d433;
    border: 1px solid transparent;
  } 
  .chakra-checkbox.def_check_box span[data-checked]{
    background: #fff;
    color: #000000;
  }
  .userlist_table_prnt .clctn_last_flex_ab{
    border-left: 1px solid #393939;
  }
  .chakra-heading.invos_head{
    color: #FFFFFF;
  }
  .inout_prnt_bx span{
    color: #FFFFFF;
  }
  .inout_prnt_bx input{
    background: #4E2C47;
    color: #fff;
    &::placeholder{
      color: #fff;
    }
  }
  .on_xdc_tabl{
    border-top: 1px solid #6D2E60;
    color: #fff;
  }
  .Invoice_in_ab_body .table_body_swpcl_tbl tr td{
    color: #fff !important;
  }
  .table_body_swpcl_tbl tr td{
    color: #303030 !important;
  }
  .Invoice_in_ab{
    .task_nme_nmbr{
      background: none !important;
    }
  }
  .brd_non{
    border: none !important;
  }
  .invos_tbl_ab{
    .t_had{
      background: #6D2E60 !important;
    }
    .t_foot{
      background: #4E2C46 !important;
    }
  }
  .Metamask_pop_body{
    p{
      color: #ccbebe;
    }
  }
  .Payment_Confirmationab_body{
    tr{
      td{
        color: #fff !important;
      }
      .for_grin_color{
        color: #49FF83 !important;
      }
    }
  }
  .Payment_Confirmationab .moda_hed p{
    color: #fff;
  }
  
}
.chakra-ui-dark .Invoice_in_ab_body .table_body_swpcl_tbl tr .for_grin_color{
  color: #00C840 !important;
  font-weight: 600 !important;
}
.chakra-ui-dark .Metamask_pop_body .ple_provi_p {
  color: #ffffff;
}
.chakra-ui-dark .merta_contin_p{
  color: #ffffff !important;
}
.chakra-ui-dark .for_rejacr_invo > p{
  color: #ffffff !important;
}
.chakra-ui-dark .rejact_pink_btn{
  &:hover{
    color: #ee0f0f !important;
  }
}
.chakra-ui-dark .Reject_reason_bx p{
  color: #fff;
}
.chakra-ui-dark .Reject_reason_bx{
  .reject_text_era{
    color: #ffffff;
  }
}